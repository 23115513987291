// frontend/src/features/email/dynamicUi/VerifiedBadge.tsx

import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningIcon from "@mui/icons-material/Warning";
import { Badge, Tooltip } from "@mui/material";
import React from "react";

interface VerifiedBadgeProps {
  tooltip: string;
  status?: "success" | "warning" | "error";
  message?: string;
}

const VerifiedBadge: React.FC<VerifiedBadgeProps> = ({ tooltip, status, message }) => {
  const getIcon = () => {
    switch (status) {
      case "success":
        return <VerifiedIcon fontSize="small" color="primary" />;
      case "warning":
        return <WarningIcon fontSize="small" color="warning" />;
      case "error":
        return <ErrorIcon fontSize="small" color="error" />;
      default:
        return null;
    }
  };

  const icon = getIcon();

  if (!icon) return null;

  return (
    <Tooltip title={message || tooltip}>
      <Badge>{icon}</Badge>
    </Tooltip>
  );
};

export default VerifiedBadge;
