import MyAuthzProvider from "@core/auth/authz/MyAuthzProvider";
import LabellerPage from "@features/labeller/LabellerPage";
import SheetImportPage from "@features/sheet_import/SheetImportPage";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";
import SettingsPage from "./features/Settings";
import SyncPage from "./features/Sync/SyncPage";
import OrganizationListPage from "./features/admin_organization/OrganizationListPage";
import WorkflosListPage from "./features/admin_workflows/WorkflowListPage";
import UserListPage from "./features/admin_user/UserListPage";
import AuthContextProvider from "./features/auth/AuthProvider";
import Login from "./features/auth/Login";
import ProfilePage from "./features/auth/Profile";
import UserDeactivatedPage from "./features/auth/UserDeactivatedPage";
import { Role } from "./features/auth/authWrappers";
import BenchmarkDetailPage from "./features/benchmark/BenchmarkDetailPage";
import BenchmarkListPage from "./features/benchmark/BenchmarkListPage";
import ContactListPage from "./features/contact/ContactListPage";
import DebugPage from "./features/debug";
import { DevSettingsProvider } from "./features/debug/devSettings/DevSettings";
import EmailDetailPage from "./features/email/EmailDetailPage";
import EmailListPage from "./features/email/EmailListPage";
import NavigationDrawer from "./features/navigation/NavigationDrawer";
import WorkflowListPage from "./features/workflow/WorkflowListPage";
import ProductListPage from "./features/product/ProductListPage";
import usePageTracking from "./features/tracking/useTracking";
import UploadPage from "./features/upload/UploadPage";
import "./i18n";
import AddressListPage from "@features/address/AddressListPage";

function App() {
  usePageTracking();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DevSettingsProvider>
        <AuthContextProvider>
          <MyAuthzProvider>
            <SnackbarProvider autoHideDuration={4000} />
            <CssBaseline />
            <NavigationDrawer>
              <Routes>
                <Route path="/profile" element={<ProfilePage allowedRoles={[Role.User]} />} />
                <Route path="/deactivated" element={<UserDeactivatedPage allowedRoles={[Role.User]} />} />

                <Route path="/settings" element={<SettingsPage allowedRoles={[Role.User]} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<WorkflowListPage allowedRoles={[Role.User]} />} />
                <Route
                  path="/workflow/:workflowId/email/:id"
                  element={<EmailDetailPage allowedRoles={[Role.User]} />}
                />

                <Route path="/products" element={<ProductListPage allowedRoles={[Role.User]} />} />
                <Route
                  path="/products/upload"
                  element={<UploadPage allowedRoles={[Role.User]} recordType="products" />}
                />

                <Route path="/contacts" element={<ContactListPage allowedRoles={[Role.User]} />} />
                <Route
                  path="/contacts/upload"
                  element={<UploadPage allowedRoles={[Role.User]} recordType="contacts" />}
                />

                <Route path="/addresses" element={<AddressListPage allowedRoles={[Role.User]} />} />
                
                <Route path="/benchmarks" element={<BenchmarkListPage allowedRoles={[Role.User]} />} />
                <Route path="/benchmark/:id" element={<BenchmarkDetailPage allowedRoles={[Role.User]} />} />

                <Route path="/workflow" element={<WorkflowListPage allowedRoles={[Role.User]} />} />
                <Route path="/workflow/:id" element={<EmailListPage allowedRoles={[Role.User]} />} />

                <Route path="/admin/user" element={<UserListPage allowedRoles={[]} />} />

                <Route path="/admin/workflow" element={<WorkflowListPage allowedRoles={[Role.User]} />} />

                <Route path="/admin/organization" element={<OrganizationListPage allowedRoles={[Role.User]} />} />

                <Route path="/debug" element={<DebugPage />} />
                <Route path="/sync" element={<SyncPage allowedRoles={[Role.User]} />} />
                <Route path="/sheet_import" element={<SheetImportPage allowedRoles={[Role.User]} />} />
                <Route path="/labeller" element={<LabellerPage />} />
              </Routes>
            </NavigationDrawer>
          </MyAuthzProvider>
        </AuthContextProvider>
      </DevSettingsProvider>
    </LocalizationProvider>
  );
}

export default App;
