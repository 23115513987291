import { SearchUISchemaField } from "@features/email/dynamicUi/dynamicUiTypes";
import { AutocompleteRenderOptionState, Checkbox, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { ComponentProps, HTMLAttributes, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export interface IAutocompleteOption {
  id: string;
  option_text: string;
  option_details?: string[];
  metadata?: unknown;
}

export interface IAutocompleteRequest {
  id?: string;
  search?: string;
}

export interface IAutocompleteResponse {
  results: IAutocompleteOption[];
}

interface ServerSideSearchSelectMultipleProps {
  advancedSearchParameters?: SearchUISchemaField["advancedSearchParameters"];
  disabled?: boolean;
  inputProps?: ComponentProps<typeof TextField>;
  label: string;
  lazyQuery: any;
  metadataName: string;
  name: string;
  onBlur: () => void;
  onChange: (value: string[]) => void;
  searchModel: string;
  value: string[];
}

const ServerSideSearchSelectMultiple = forwardRef<HTMLDivElement, ServerSideSearchSelectMultipleProps>(
  (
    {
      advancedSearchParameters,
      disabled,
      inputProps,
      label,
      lazyQuery,
      metadataName,
      name,
      onBlur,
      onChange: onChangeProps,
      searchModel,
      value,
    },
    ref,
  ) => {
    const form = useFormContext();

    const [selectedOptions, setSelectedOptions] = useState<IAutocompleteOption[]>([]);
    const [inputValue, setInputValue] = useState("");

    const extraParameters = useMemo(() => {
      if (advancedSearchParameters && advancedSearchParameters.length > 0) {
        return advancedSearchParameters.reduce(
          (acc, curr) => {
            const value = form.getValues(`${curr.valueFromField}.value`);

            if (value) {
              acc[curr.key] = value;
            }

            return acc;
          },
          {} as Record<string, any>,
        );
      }

      return {};
    }, [advancedSearchParameters, form]);

    const [trigger, { data, isFetching, isUninitialized }] = lazyQuery();

    const debouncedTrigger = debounce(trigger, 300);

    const options = useMemo(() => {
      const typedResults = (data?.results ?? []) as IAutocompleteOption[];
      const uniqueResults = typedResults.filter(
        (option, index, arr) => index === arr.findIndex((t) => t.id === option.id),
      );

      return uniqueResults;
    }, [data?.results]);

    const onChange = useCallback(
      (value: string[], metadata?: unknown) => {
        onChangeProps(value);
        form.setValue(metadataName, metadata ?? null, { shouldValidate: false });
      },
      [form, metadataName, onChangeProps],
    );

    // When component mounts, if value is not empty, fetch the selected options
    useEffect(() => {
      if (value && value.length > 0) {
        trigger({ searchModel, params: { id: value.join(",") } });
      } else {
        trigger({ searchModel, params: { ...extraParameters, search: "" } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (value === undefined || value.length === 0) {
        if (selectedOptions.length > 0) {
          setSelectedOptions([]);
        }

        return;
      }

      const remainingSelectedOptions = selectedOptions.filter((option) => value.includes(option.id));

      const foundSelectedOptions = value
        .map((selectedValue) => options.find((x) => x.id.toString() === selectedValue.toString()))
        .filter(Boolean) as IAutocompleteOption[];

      if (foundSelectedOptions && foundSelectedOptions.length > 0) {
        const newSelectedOptions = [...remainingSelectedOptions, ...foundSelectedOptions].filter(
          (option, index, self) => index === self.findIndex((t) => t.id.toString() === option.id.toString()),
        );

        const selectedOptionsChanged =
          newSelectedOptions
            .map((option) => option.id)
            .sort()
            .join(",") !==
          selectedOptions
            .map((option) => option.id)
            .sort()
            .join(",");
        const valueChanged =
          newSelectedOptions
            .map((option) => option.id)
            .sort()
            .join(",") !== value.sort().join(",");

        if (selectedOptionsChanged) {
          setSelectedOptions(newSelectedOptions);
        }

        if (valueChanged) {
          onChange(
            newSelectedOptions.map((option) => option.id),
            newSelectedOptions.map((option) => option.metadata),
          );
        }
      } else if (
        (!foundSelectedOptions || foundSelectedOptions.length === 0) &&
        (!remainingSelectedOptions || remainingSelectedOptions.length === 0) &&
        !isUninitialized &&
        !isFetching
      ) {
        setSelectedOptions([]);
        onChange([]);
      }
    }, [value, options, selectedOptions, isFetching, onChange, isUninitialized]);

    const handleInputChange = useCallback(
      (_: any, value: string) => {
        setInputValue(value);
        debouncedTrigger({ searchModel, params: { ...extraParameters, search: value } });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchModel, extraParameters],
    );

    const handleChange = useCallback(
      (_: any, selectedOptions: IAutocompleteOption[]) => {
        if (selectedOptions && selectedOptions.length > 0) {
          onChange(
            selectedOptions.map((option) => option.id),
            selectedOptions.map((option) => option.metadata),
          );
        } else {
          onChange([]);
        }
      },
      [onChange],
    );

    const renderOption = useCallback(
      (
        props: HTMLAttributes<HTMLLIElement>,
        option: IAutocompleteOption,
        { selected }: AutocompleteRenderOptionState,
      ) => (
        <li {...props} key={`listItem-${option.id}`}>
          <Checkbox checked={selected} />
          <Box>
            <Typography variant="body1">{option.option_text}</Typography>
            {option.option_details &&
              option.option_details.map((val) => (
                <Typography variant="caption" key={val} display="block">
                  {val}
                </Typography>
              ))}
          </Box>
        </li>
      ),
      [],
    );

    return (
      <Autocomplete
        disableCloseOnSelect
        multiple
        ref={ref}
        disabled={disabled}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.option_text?.toString()}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.id.toString() === value.id.toString()}
        loading={isFetching}
        noOptionsText="Nessun risultato"
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            disabled={disabled || inputProps?.disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
            name={name}
          />
        )}
        renderOption={renderOption}
        value={selectedOptions}
      />
    );
  },
);

export default ServerSideSearchSelectMultiple;
