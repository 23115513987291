import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGetWorkflowQuery, useUpdateWorkflowMutation } from "../../app/services/appApi";
import { IWorkflowRead, IWorkflowUpdate } from "../auth/types";

const cleanReadWorkflowDataForUpdate = (workflow: IWorkflowRead): IWorkflowUpdate => ({
  title: workflow.title,
  subtitle: workflow.subtitle,
  memories: workflow.memories,
  code: workflow.code,
  ratelimit: workflow.ratelimit,
  ui_schema: workflow.ui_schema,
  extraction_schema: workflow.extraction_schema,
  organization_id: workflow.organization.id,
  extraction_extra_context: workflow.extraction_extra_context,
  name: workflow.name,
  enable_register: workflow.enable_register,
  tool_config: workflow.tool_config,
});

interface IWorkflowFormDialogProps {
  workflowId?: IWorkflowRead["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for editing workflow's settings.
 * It can be opened only in edit mode by user
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */
const WorkflowSettings: React.FC<IWorkflowFormDialogProps> = ({ workflowId, render }) => {
  const [open, setOpen] = useState(false);

  const form = useForm<IWorkflowUpdate>();

  // Delay data fetching until userId is defined and dialog is opened
  const { data: workflow, isLoading: isDataLoading } = useGetWorkflowQuery(workflowId ?? skipToken, { skip: !open });

  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateWorkflowMutation();

  const isLoading = isUpdateLoading || isDataLoading;

  React.useEffect(() => {
    if (workflow) {
      form.reset(cleanReadWorkflowDataForUpdate(workflow));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data: IWorkflowUpdate) => {
    triggerUpdate({ id: workflowId!, ...data });
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornato con successo", { variant: "success" });
      handleClose();
    }
  }, [isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <SettingsIcon />
    </IconButton>
  );
  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Modifica flusso di lavoro</DialogTitle>
        {!isDataLoading ? (
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <DialogContent>
              <Stack gap={2}>
                <Controller
                  name="title"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Title"}
                      type="text"
                      placeholder="Il mio flusso di lavoro"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Nome del flusso"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="subtitle"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Subtitle"}
                      type="text"
                      placeholder="Flusso di lavoro che fa cose"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error ? "Il campo è obbligatorio" : "Descrizione del flusso"}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="memories"
                  control={form.control}
                  defaultValue=""
                  rules={{ maxLength: 1024 }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"Memories"}
                      type="text"
                      fullWidth
                      multiline
                      placeholder="# Additional context"
                      rows={4}
                      maxRows={20}
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? "Il campo deve contenere massimo 1024 caratteri"
                          : "Opzionale. Possibilità di aggiungere delle note e memorie per il flusso di lavoro. Il numero massimo di caratteri è 1024"
                      }
                      {...field}
                    />
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
              <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
                Annulla
              </Button>
              <LoadingButton
                startIcon={<CheckOutlinedIcon />}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                type="submit"
              >
                Aggiorna
              </LoadingButton>
            </DialogActions>
          </form>
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default WorkflowSettings;
