import { useUploadEmailMutation } from "@app/services/appApi";
import { UploadFileOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
  alpha,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const theme = createTheme();

interface DropZoneProps {
  isActive: boolean;
  hasFile: boolean;
}

interface FileUploadDialogProps {
  onClose: () => void;
}

const DropZone = styled(Box)<DropZoneProps>(({ theme, isActive, hasFile }) => ({
  border: `2px dashed ${isActive ? theme.palette.primary.main : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  textAlign: "center",
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  backgroundColor: isActive
    ? alpha(theme.palette.primary.light, 0.1)
    : hasFile
      ? alpha(theme.palette.primary.light, 0.05)
      : "transparent",
  transition: "all 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { id: workflowId } = useParams();
  const [uploadEmail, { isLoading }] = useUploadEmailMutation();
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [groupFiles, setGroupFiles] = React.useState<boolean>(false);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setSelectedFiles((prevFiles) => {
      // Filter out duplicate files based on name and size
      const newFiles = acceptedFiles.filter(
        (newFile) =>
          !prevFiles.some((existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size),
      );
      return [...prevFiles, ...newFiles];
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"], "application/vnd.ms-excel": [".xls", ".xlsx"], "text/csv": [".csv"] },
    multiple: true,
  });

  const handleUpload = async () => {
    if (!selectedFiles.length || !workflowId) return;
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("files", file); // Changed from "file" to "files"
      });

      await uploadEmail({
        workflowId: Number(workflowId),
        files: formData,
        group: groupFiles,
      }).unwrap();

      onClose();
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Carica uno o più file")}</DialogTitle>
        <FormControlLabel
          sx={{ ml: 2 }}
          control={<Switch checked={groupFiles} onChange={(e) => setGroupFiles(e.target.checked)} />}
          label={t("Raggruppa più file in una mail")}
        />

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <input multiple id="file-input" style={{ display: "none" }} type="file" />
          <input
            id="directory-input"
            style={{ display: "none" }}
            type="file"
            // @ts-ignore
            webkitdirectory="true"
          />
          <Stack gap={1} direction="row" justifyContent="center">
            <InputLabel htmlFor="file-input" sx={{ flex: 1 }}>
              <DropZone {...getRootProps()} hasFile={selectedFiles.length > 0} isActive={isDragActive}>
                <input {...getInputProps()} />
                <UploadFileOutlined sx={{ fontSize: 48, mb: 2 }} />
                <Typography variant="h6" component="div" gutterBottom>
                  {t("Trascina un file qui")}
                </Typography>
                <Typography variant="body2">{t("o clicca per selezionare")}</Typography>
                {selectedFiles.length > 0 && (
                  <Box mt={2}>
                    <Typography variant="body2">
                      {t("File selezionati")}: {selectedFiles.length}
                    </Typography>
                  </Box>
                )}
              </DropZone>
            </InputLabel>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Cancella")}</Button>
          <LoadingButton
            color="primary"
            variant="contained"
            disabled={selectedFiles.length === 0}
            loading={isLoading}
            onClick={handleUpload}
          >
            {t("Carica")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default FileUploadDialog;
