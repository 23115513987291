import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabellerCanvas from "./LabellerCanvas";
import LabellerSidebar from "./LabellerSidebar";
import { BoundingBox, Label, PageTag } from "./labellerTypes";

const LabellerPage: React.FC = () => {
  const [boxes, setBoxes] = useState<BoundingBox[]>([]);
  const [labels, setLabels] = useState<Label[]>(
    JSON.parse(localStorage.getItem("labels") || '[{ "name": "Label 1", "color": "#ff7f7f" }]'),
  );
  const [tags, setTags] = useState<PageTag[]>([]);
  const [originalFileName, setOriginalFileName] = useState("");

  // When labels change, persist them to local storage
  useEffect(() => {
    localStorage.setItem("labels", JSON.stringify(labels));
  }, [labels]);

  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Box sx={{ flexGrow: 1, overflow: "auto", maxWidth: "calc(100% - 300px)" }}>
        <LabellerCanvas
          value={boxes}
          onChange={setBoxes}
          labels={labels}
          tags={tags}
          onTagsChange={setTags}
          originalFileName={originalFileName}
          setOriginalFileName={setOriginalFileName}
        />
      </Box>
      <Box sx={{ width: "300px", borderLeft: "1px solid #ccc", overflow: "auto" }}>
        <LabellerSidebar
          boxes={boxes}
          onBoxesChange={setBoxes}
          labels={labels}
          onLabelsChange={setLabels}
          tags={tags}
          onTagsChange={setTags}
          originalFileName={originalFileName}
        />
      </Box>
    </Box>
  );
};

export default LabellerPage;
