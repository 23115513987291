import { IArtifactRead } from '../auth/types';
import { Box, Typography, List, ListItem, ListItemText, IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Article, InsertDriveFile, PictureAsPdf, Image, GridOn } from '@mui/icons-material';

interface IArtifactListPageProps {
    artifacts: IArtifactRead[]
}

export default function ArtifactListPage(props: IArtifactListPageProps) {
    props.artifacts.map((artifact) => {
        const filename = artifact.filename;
    });
    const getFileIcon = (filename: string) => {
        const extension = filename.split('.').pop()?.toLowerCase();
        if (extension === 'pdf') {
            return <PictureAsPdf />;
        } else if (extension === 'txt'){
            return <Article />;
        }else if (extension === 'jpg'){
                return <Image />;
        }else if (extension ==='csv' || extension === 'xlsx' || extension === 'xls'){
                return <GridOn />;
        }else {
                return <InsertDriveFile />; 
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    mb: 4,
                    mt: 4,
                    ml: 2,
                }}
            >
                <AttachFileIcon 
                    sx={{ fontSize: 25 }}
                />
                <Typography variant="h5" component="h1" noWrap>
                    <b>Artifacts List</b>
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 3,
                }}
            >
            </Box>
            <List>
                {props.artifacts.map((artifact)  => (
                    <ListItem
                        key={artifact.id}
                        divider
                        secondaryAction={
                            <Tooltip title="Download">
                                <IconButton
                                    edge="end"
                                    color="primary"
                                    component="a"
                                    href={artifact.presigned_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        {getFileIcon(artifact.filename)}
                        <ListItemText primary={artifact.filename}
                            sx={{ml: 4}} />
                    </ListItem>
                ))}
            </List>
        </>
    );
}