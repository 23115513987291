import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IAddressRecord, IAddressRequest } from "./addressTypes";
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetAddressQuery, useCreateAddressMutation, useUpdateAddressMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

interface IAddressFormDialogProps {
  addressId?: IAddressRecord["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

interface IAddressFormProps {
  defaultValues: IAddressRequest;
  createMode?: boolean;
  isLoading: boolean;
  onSubmit: (data: IAddressRequest) => void;
  onClose: () => void;
}

const AddressForm: React.FC<IAddressFormProps> = ({ defaultValues, onSubmit, onClose, isLoading, createMode }) => {
  const {
    control,
    handleSubmit,
  } = useForm<IAddressRequest>({ 
    defaultValues,
    mode: 'onChange' 
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack gap={2}>
       <Controller
            control={control}
            name="code"
            rules={{ required: 'Questo campo è obbligatorio', minLength: {
              value: 3,
              message: 'Il campo deve contenere almeno 3 caratteri'
            }}}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label="Codice indirizzo"
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />

        <Controller
            control={control}
            name="formatted_address"
            rules={{ required: 'Questo campo è obbligatorio', minLength: {
              value: 3,
              message: 'Il campo deve contenere almeno 3 caratteri'
            }}}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label="Indirizzo formattato"
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />

        <Controller
            control={control}
            name="contact_id"
            rules={{ required: 'Questo campo è obbligatorio', minLength: {
              value: 3,
              message: 'Il campo deve contenere almeno 3 caratteri'
            }}}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label="Id contatto"
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />


        <Controller
            control={control}
            name="google_maps_link"
            rules={{ required: 'Questo campo è obbligatorio', minLength: {
              value: 3,
              message: 'Il campo deve contenere almeno 3 caratteri'
            }}}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label="Link Google Maps"
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />

        <Controller
            control={control}
            name="geocoding_status"
            rules={{ required: 'Questo campo è obbligatorio', minLength: {
              value: 3,
              message: 'Il campo deve contenere almeno 3 caratteri'
            }}}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label="Geo codice"
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Button
          startIcon={<CloseOutlinedIcon />}
          onClick={onClose}>
          Annulla
        </Button>
        <LoadingButton
          startIcon={<CheckOutlinedIcon />}
          loading={isLoading}
          disabled={isLoading}
          variant='contained' 
          type="submit">
          {createMode ? "Crea" : "Aggiorna"}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const AddressFormDialog: React.FC<IAddressFormDialogProps> = ({ addressId, render }) => {
  const [open, setOpen] = useState(false);
  const createMode = !addressId;

  const { data: address, isLoading: isAddressLoading } = useGetAddressQuery(addressId ?? -1, { 
    skip: createMode || !open 
  });

  const [triggerCreate, {
    isLoading: isCreateLoading,
    isSuccess: isCreateSuccess,
    isError: isCreateError
  }] = useCreateAddressMutation();

  const [triggerUpdate, {
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError
  }] = useUpdateAddressMutation();

interface IAddressUpdateRequest extends IAddressRequest {
    id: number;
}

const defaultAddressValues: IAddressRequest = createMode 
    ? { code: "", formatted_address: "", contact_id: 0, google_maps_link: "", geocoding_status: "" }
    : address ? { ...address, contact_id: Number(address.contact_id) } : { code: "", formatted_address: "", contact_id: 0, google_maps_link: "", geocoding_status: "" };

const handleClose = () => {
    setOpen(false);
};

const handleSubmit = async (data: IAddressRequest) => {
    try {
        if (createMode) {
            await triggerCreate(data).unwrap();
        } else {
            const updateData: IAddressUpdateRequest = { id: addressId!, ...data };
            await triggerUpdate(updateData).unwrap();
        }
    } catch (error) {
        console.error('Error submitting form:', error);
    }
};

  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar("Errore creazione", { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar("Creato con successo", { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornato con successo", { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render 
    ? render({ onClick: () => setOpen(true) }) 
    : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? "Crea nuovo indirizzo" : "Modifica indirizzo"}</DialogTitle>
        {(isAddressLoading && !createMode) ? (
          <DialogContent>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          </DialogContent>
        ) : (
          <AddressForm
            createMode={createMode}
            isLoading={isCreateLoading || isUpdateLoading}
            onSubmit={handleSubmit}
            onClose={handleClose}
            defaultValues={defaultAddressValues}
          />
        )}
      </Dialog>
    </>
  );
};

export default AddressFormDialog;