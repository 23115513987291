import { API_PATH, useStartLogStreamingMutation, useStopLogStreamingMutation } from "@app/services/appApi";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";

const GatewayLogStream: React.FC = () => {
  const [logs, setLogs] = useState<Array<{ timestamp: string; message: string }>>([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logContainerRef = useRef<HTMLDivElement>(null);

  const [startLogStreaming] = useStartLogStreamingMutation();
  const [stopLogStreaming] = useStopLogStreamingMutation();

  const connectSocket = useCallback(() => {
    const wsBaseUrl = window.location.origin.replace(/^http/, "ws");
    const wsNamespace = "/ui";
    const wsUrl = `${wsBaseUrl}${wsNamespace}`;
    const newSocket = io(wsUrl, {
      path: `${API_PATH}socket.io`,
      transports: ["websocket"],
      autoConnect: false,
    });

    newSocket.on("connect", () => {
      setIsConnected(true);
      newSocket.emit("join_organization_room");
    });

    newSocket.on("disconnect", () => {
      setIsConnected(false);
    });

    newSocket.on("log_entry", (data) => {
      setLogs((prevLogs) => [...prevLogs, data]);
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    newSocket.io.on("error", (error) => {
      console.error("Socket error:", error);
    });

    // Authenticate and connect
    const token = localStorage.getItem("token");
    if (token) {
      newSocket.auth = { token };
      newSocket.connect();
    } else {
      console.error("No authentication token found");
    }

    setSocket(newSocket);
    return newSocket;
  }, []);

  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
      setIsConnected(false);
    }
  }, [socket]);

  const handleStartStreaming = async () => {
    setIsLoading(true);
    setLogs([]);
    try {
      const result = await startLogStreaming();
      if ("data" in result && result.data.status === "success") {
        const newSocket = connectSocket();
        setSocket(newSocket);
        setIsStreaming(true);
      }
    } catch (error) {
      console.error("Failed to start log streaming", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStopStreaming = async () => {
    setIsLoading(true);
    try {
      const result = await stopLogStreaming();
      if ("data" in result && result.data.status === "success") {
        disconnectSocket();
        setIsStreaming(false);
      }
    } catch (error) {
      console.error("Failed to stop log streaming", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Auto-scroll effect
  React.useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <Box sx={{ width: "100%", maxWidth: 800, margin: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Gateway Logs
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Button
          onClick={isStreaming ? handleStopStreaming : handleStartStreaming}
          variant="contained"
          color={isStreaming ? "error" : "primary"}
          startIcon={isStreaming ? <StopIcon /> : <PlayArrowIcon />}
          disabled={isLoading}
          sx={{ mr: 2 }}
        >
          {isStreaming ? "Stop Streaming" : "Start Streaming"}
        </Button>
        {isLoading && <CircularProgress size={24} sx={{ mr: 2 }} />}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FiberManualRecordIcon color={isConnected ? "success" : "error"} sx={{ mr: 1, fontSize: 16 }} />
          <Typography variant="body2">{isConnected ? "Connected" : "Disconnected"}</Typography>
        </Box>
      </Box>
      <Paper
        elevation={3}
        ref={logContainerRef}
        sx={{
          height: "400px",
          overflowY: "auto",
          bgcolor: "#1e1e1e",
          color: "#f0f0f0",
          p: 2,
          fontFamily: "monospace",
          fontSize: "0.9rem",
        }}
      >
        {logs.map((log, index) => (
          <Box key={index} component="pre" sx={{ m: 0, whiteSpace: "pre-wrap" }}>
            <span style={{ color: "#888" }}>[{log.timestamp}]</span> {log.message}
          </Box>
        ))}
      </Paper>
    </Box>
  );
};

export default GatewayLogStream;
