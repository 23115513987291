import ServerSideSearchSelectMultiple from "@features/input/select/ServerSideSearchSelectMultiple";
import { GpsFixed as GpsFixedIcon } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { HighlightArea } from "@react-pdf-viewer/highlight";
import moment from "moment";
import React, { useCallback } from "react";
import { Control, Controller, ValidationRule, useFormContext } from "react-hook-form";
import { useLazyGetAutocompleteQuery } from "../../../app/services/appApi";
import ServerSideSearchSelect2 from "../../input/select/ServerSideSearchSelect2";
import MessagePopup from "./MessagePopup";
import { UISchemaField } from "./dynamicUiTypes";

interface FieldRendererProps {
  fieldName: string;
  fieldSchema: UISchemaField;
  control: Control<any>;
  index?: number;
  disabled?: boolean;
  onTarget: (target: EmailViewer) => void;
  page_idx: number;
  tabID: string;
}

export interface EmailViewer extends HighlightArea {
  pageIndex: number;
  height: number;
  width: number;
  left: number;
  top: number;
  tabID: string;
  label: string;
  value: string;
}

const FieldRenderer: React.FC<FieldRendererProps> = ({
  fieldName,
  fieldSchema,
  control,
  index,
  disabled,
  onTarget,
}) => {
  const name = index !== undefined ? `items[${index}].${fieldName}.value` : `${fieldName}.value`;
  const bboxFieldName = index !== undefined ? `items[${index}].${fieldName}.bbox` : `${fieldName}.bbox`;
  const messagesFieldName = index !== undefined ? `items[${index}].${fieldName}.messages` : `${fieldName}.messages`;
  const metadataName = index !== undefined ? `items[${index}].${fieldName}.metadata` : `${fieldName}.metadata`;

  const form = useFormContext();
  const bboxValues = form.watch(bboxFieldName);
  const messages = form.getValues(messagesFieldName);

  const isBboxAbsent = Array.isArray(bboxValues) && bboxValues.every((v) => v === 0);

  const validation: Record<string, ValidationRule<any>> = fieldSchema.validation
    ? {
        min:
          fieldSchema.validation.min !== undefined
            ? {
                value: fieldSchema.validation.min,
                message: `${fieldSchema.label} deve essere almeno ${fieldSchema.validation.min}.`,
              }
            : undefined,
        max:
          fieldSchema.validation.max !== undefined
            ? {
                value: fieldSchema.validation.max,
                message: `${fieldSchema.label} deve essere al massimo ${fieldSchema.validation.max}.`,
              }
            : undefined,
        pattern: fieldSchema.validation.pattern
          ? { value: new RegExp(fieldSchema.validation.pattern), message: "Formato non valido." }
          : undefined,
      }
    : {};

  const handleTargetClick = () => {
    const bboxFieldName = index !== undefined ? `items[${index}].${fieldName}.bbox` : fieldName + ".bbox";
    const src_refFieldName = index !== undefined ? `items[${index}].${fieldName}.src_ref` : `${fieldName}.src_ref`;
    const page_idxFieldName = index !== undefined ? `items[${index}].${fieldName}.page_idx` : `${fieldName}.page_idx`;

    const bboxValues = form.getValues(bboxFieldName);
    if (!bboxValues) {
      return;
    }

    const src_ref = form.getValues(src_refFieldName);
    if (!src_ref) {
      return;
    }
    const { type, id } = src_ref;
    if (!type || !id) {
      return;
    }

    const page_idxValue = form.getValues(page_idxFieldName);
    if (page_idxValue === undefined) {
      return;
    }

    const pageIndex = page_idxValue;
    let tabString: string;
    if (type === "attachment") {
      tabString = "attachment" + "::" + id;
    } else {
      tabString = "email";
    }

    const PADDING = 0.01;
    const x0 = Math.max(bboxValues[0] - PADDING, 0);
    const x1 = Math.min(bboxValues[2] + PADDING, 1);
    const y0 = Math.max(bboxValues[1] - PADDING, 0);
    const y1 = Math.min(bboxValues[3] + PADDING, 1);

    const width = (x1 - x0) * 100;
    const height = (y1 - y0) * 100;

    const targetBbox: EmailViewer = {
      width,
      height,
      left: x0 * 100,
      top: y0 * 100,
      pageIndex: pageIndex,
      tabID: tabString,
      label: fieldSchema.label,
      value: form.getValues(name) || "",
    };
    onTarget(targetBbox);
  };

  const renderTargetButton = () =>
    !isBboxAbsent && (
      <InputAdornment position="end">
        <IconButton edge="end" onClick={handleTargetClick} size="small">
          <GpsFixedIcon />
        </IconButton>
      </InputAdornment>
    );

  const renderMessagePopup = () => {
    if (messages && messages.length > 0) {
      return <MessagePopup messages={messages} />;
    }
    return null;
  };

  const renderInputProps = (additionalProps = {}) => ({
    ...additionalProps,
    endAdornment: (
      <>
        {renderTargetButton()}
        {renderMessagePopup()}
      </>
    ),
  });

  const handleChange = useCallback(
    (value: string | string[], metadata?: unknown) => {
      form.setValue(name, value, { shouldValidate: true });
      form.setValue(metadataName, metadata ?? null, { shouldValidate: false });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metadataName, name],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      disabled={disabled}
      render={({ field, fieldState: { error } }) => {
        switch (fieldSchema.type) {
          case "string":
            return (
              <TextField
                {...field}
                value={field.value || ""}
                label={fieldSchema.label}
                fullWidth
                size="small"
                error={!!error}
                helperText={error?.message}
                InputProps={renderInputProps()}
                onFocus={handleTargetClick}
              />
            );
          case "multiline":
            return (
              <TextField
                {...field}
                value={field.value || ""}
                label={fieldSchema.label}
                fullWidth
                size="small"
                multiline
                rows={3}
                error={!!error}
                helperText={error?.message}
                InputProps={renderInputProps()}
                onFocus={handleTargetClick}
              />
            );
          case "int":
            return (
              <TextField
                {...field}
                value={field.value || ""}
                label={fieldSchema.label}
                fullWidth
                size="small"
                type="number"
                inputProps={{ step: 1 }}
                error={!!error}
                helperText={error?.message}
                InputProps={renderInputProps()}
                onFocus={handleTargetClick}
              />
            );
          case "float":
            return (
              <TextField
                {...field}
                value={field.value || ""}
                label={fieldSchema.label}
                fullWidth
                size="small"
                type="number"
                inputProps={{ step: 0.01 }}
                error={!!error}
                helperText={error?.message}
                InputProps={renderInputProps()}
                onFocus={handleTargetClick}
              />
            );
          case "date":
            return (
              <DatePicker
                {...field}
                value={field.value ? moment(field.value) : null}
                label={fieldSchema.label}
                formatDensity="spacious"
                onChange={(newValue) => {
                  if (newValue) {
                    const date = newValue.toDate();
                    const formattedDate =
                      date.getFullYear() +
                      "-" +
                      String(date.getMonth() + 1).padStart(2, "0") +
                      "-" +
                      String(date.getDate()).padStart(2, "0");
                    field.onChange(formattedDate);
                  } else {
                    field.onChange(null);
                  }
                }}
                slotProps={{
                  textField: {
                    helperText: error?.message,
                    error: !!error,
                    size: "small",
                    InputProps: renderInputProps(),
                    onFocus: handleTargetClick,
                  },
                }}
              />
            );
          case "search":
            return fieldSchema.multiple ? (
              <ServerSideSearchSelectMultiple
                {...field}
                advancedSearchParameters={fieldSchema.advancedSearchParameters}
                inputProps={{
                  placeholder: "Digita per cercare...",
                  size: "small",
                  error: !!error,
                  helperText: error?.message,
                  InputProps: renderInputProps(),
                  onFocus: handleTargetClick,
                }}
                label={fieldSchema.label}
                lazyQuery={useLazyGetAutocompleteQuery}
                metadataName={metadataName}
                searchModel={fieldSchema.searchModel}
              />
            ) : (
              <ServerSideSearchSelect2
                {...field}
                advancedSearchParameters={fieldSchema.advancedSearchParameters}
                inputProps={{
                  placeholder: "Digita per cercare...",
                  size: "small",
                  error: !!error,
                  helperText: error?.message,
                  InputProps: renderInputProps(),
                  onFocus: handleTargetClick,
                }}
                label={fieldSchema.label}
                lazyQuery={useLazyGetAutocompleteQuery}
                onChange={handleChange}
                searchModel={fieldSchema.searchModel}
              />
            );
          case "enum":
            return (
              <FormControl fullWidth size="small" error={!!error} {...field} onFocus={handleTargetClick}>
                <InputLabel id={`${name}-label`}>{fieldSchema.label}</InputLabel>
                {fieldSchema.multiple ? (
                  <Select
                    {...field}
                    multiple
                    labelId={`${name}-label`}
                    id={`${name}-select`}
                    label={fieldSchema.label}
                    endAdornment={renderMessagePopup()}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value: string) =>
                            fieldSchema.options.find((option) => option.value.toString() === value.toString())?.label,
                        )
                        .join(", ")
                    }
                  >
                    {fieldSchema.options?.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox checked={Array.isArray(field.value) && field.value.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Select
                    {...field}
                    labelId={`${name}-label`}
                    id={`${name}-select`}
                    label={fieldSchema.label}
                    endAdornment={renderMessagePopup()}
                  >
                    {fieldSchema.options?.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            );
        }
      }}
    />
  );
};

export default FieldRenderer;
