import { Box, Button, CircularProgress, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSyncStatusQuery, useTriggerSyncMutation } from "../../app/services/appApi";
import { SyncProgress } from "./syncTypes";

type ProgressType = SyncProgress["addresses"] | SyncProgress["contacts"];

const ProgressBar: React.FC<{ progress: ProgressType; label: string }> = ({ progress, label }) => {
  const percentage = progress.total > 0 ? (progress.current / progress.total) * 100 : 0;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2">{label}</Typography>
      <LinearProgress variant="determinate" value={percentage} sx={{ mt: 1 }} />
      <Typography variant="caption">{`${Math.round(percentage)}% (${progress.current}/${progress.total})`}</Typography>
      <Typography variant="caption" display="block">
        {`Created: ${progress.created}, Updated: ${progress.updated}, Deleted: ${progress.deleted}`}
      </Typography>
    </Box>
  );
};

const SyncAllComponent: React.FC = () => {
  const { t } = useTranslation();
  const [triggerSync, { isLoading: isSyncTriggering }] = useTriggerSyncMutation();
  const [taskId, setTaskId] = useState<string | null>(null);

  const { data: syncStatus, isLoading: isSyncStatusLoading } = useGetSyncStatusQuery(
    { task_id: taskId || "" },
    { skip: !taskId, pollingInterval: 5000 },
  );

  useEffect(() => {
    if (syncStatus && syncStatus.status === "SUCCESS") {
      setTaskId(null);
    }
  }, [syncStatus]);

  const handleSyncClick = async () => {
    try {
      const result = await triggerSync().unwrap();
      setTaskId(result.task_id);
    } catch (error) {
      console.error("Failed to trigger sync:", error);
    }
  };

  const isSyncing = !!taskId;

  const getSyncStatusMessage = () => {
    if (!syncStatus) return "Initializing...";
    switch (syncStatus.status) {
      case "SYNCING_PRODUCTS":
        return "Syncing products...";
      case "SYNCING_CONTACTS":
        return "Syncing contacts...";
      case "SYNCING_ADDRESSES":
        return "Syncing addresses...";
      case "UPDATING_RELATIONS":
        return "Updating relations...";
      case "SUCCESS":
        return "Sync completed successfully!";
      default:
        return "Syncing in progress...";
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleSyncClick}
        disabled={isSyncTriggering || isSyncing}
        startIcon={isSyncTriggering || isSyncing ? <CircularProgress size={20} color="inherit" /> : null}
      >
        {t("Sync All")}
      </Button>
      {isSyncing && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">{getSyncStatusMessage()}</Typography>
          {syncStatus && syncStatus.progress && (
            <>
              <ProgressBar progress={syncStatus.progress.products} label="Products" />
              <ProgressBar progress={syncStatus.progress.contacts} label="Contacts" />
              <ProgressBar progress={syncStatus.progress.addresses} label="Addresses" />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SyncAllComponent;
