import { IAttachmentRecord } from "@features/attachment/attachmentTypes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { HighlightArea } from "@react-pdf-viewer/highlight";
import { Allotment } from "allotment";
import DOMPurify from "dompurify";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import { useGetEmailQuery, usePatchEmailMutation } from "../../app/services/appApi";
import { withAuth } from "../auth/authWrappers";
import EmailEditableFields from "./EmailEditableFields";
import EmailExtractionInfo from "./EmailExtractionInfo";
import ErrorPlaceholder from "./ErrorPlaceholder";
import LoadingPlaceholder from "./LoadingPlaceholder";
import PDFViewer from "./PDFViewer";
import EmailToolbar from "./toolbar/EmailToolbar";
import ArtifactListPage from "../artifact/ArtifactListPage";

interface HighlightAreaWithLabel extends HighlightArea {
  tabID: string;
  label: string;
}


function EmailDetailPage() {
  const { id, workflowId } = useParams<{ id: string; workflowId: string }>();
  const { data: email, isLoading, isFetching, isError } = useGetEmailQuery(Number(id), { skip: !id });
  const [patchEmail, { isLoading: isPatching, isSuccess: isPatchSuccess, isError: isPatchError }] =
    usePatchEmailMutation();
  const [value, setValue] = useState("1");
  const [page_idx, setPageIdx] = useState<number>(0);
  const [tabID, setTabID] = useState<string | null>(null);
  const [src_ref, setSrcRef] = useState<{ type: string; id: string }>({ type: "email", id: "" });
  const [activeArea, setActiveArea] = useState<HighlightAreaWithLabel | null>(null);

  useEffect(() => {
    if (isPatchSuccess) {
      enqueueSnackbar("Dati aggiornati", { variant: "success" });
    }
    if (isPatchError) {
      enqueueSnackbar("Errore durante il salvataggio", { variant: "error" });
    }
  }, [isPatchSuccess, isPatchError]);

  const onSubmit = (data: any) => {
    if (id) {
      patchEmail({ id: Number(id), body: { parsed_info: data } });
    }
  };

  const handleTarget = (target: HighlightAreaWithLabel) => {
    setActiveArea(target);
    setPageIdx(target.pageIndex);
    setTabID(target.tabID);
  };

  const handleAttachmentChange = (_: React.SyntheticEvent, newValue: string) => {
    setTabID(newValue);
    setSrcRef(newValue === "email" ? { type: "email", id: "" } : { type: "attachment", id: newValue.split("::")[1] });
  };

  useEffect(() => {
    if (email) {
      setTabID((prevTabID) => {
        if (prevTabID === null) {
          return email.attachments.length > 0 ? `attachment::${email.attachments[0].id}` : "email";
        } else if (prevTabID === "email") {
          return "email";
        } else {
          return email.attachments.length > 0 ? `attachment::${email.attachments[0].id}` : "email";
        }
      });
    }
  }, [email]);

  if (isLoading) return <LoadingPlaceholder />;
  if (isError) return <ErrorPlaceholder />;
  if (!email) return null;

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <IconButton component={Link} to={`/workflow/${workflowId}`} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1, mb: 2, width: "100px" }}>
          <Typography variant="h5" component="h1" noWrap>
            <b>{email.subject === "" ? "Email senza oggetto" : email.subject || "Caricamento email..."}</b>
          </Typography>
        </Box>
        {id && <EmailToolbar emailId={Number(id)} email={email} />}
      </Stack>
      <Allotment
        onDragEnd={(sizes) => localStorage.setItem("emailDetailSplit", JSON.stringify(sizes))}
        defaultSizes={JSON.parse(localStorage.getItem("emailDetailSplit") || "[0.66,0.33]")}
      >
        <EmailContent
          email={email}
          tabID={tabID || "email"}
          handleAttachmentChange={handleAttachmentChange}
          activeArea={activeArea}
        />
        <EmailDetails
          email={email}
          value={value}
          setValue={setValue}
          isPatching={isPatching}
          isFetching={isFetching}
          onSubmit={onSubmit}
          handleTarget={handleTarget}
          page_idx={page_idx}
          tabID={tabID || "email"}
          src_ref={src_ref}
        />
      </Allotment>
    </>
  );
}

interface EmailContentProps {
  email: any;
  tabID: string;
  handleAttachmentChange: (event: React.SyntheticEvent, newValue: string) => void;
  activeArea: HighlightAreaWithLabel | null;
}

function EmailContent({ email, tabID, handleAttachmentChange, activeArea }: EmailContentProps) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Paper sx={{ height: "100%" }}>
        <TabContext value={tabID}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabID}
              onChange={handleAttachmentChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="attachment tabs"
            >
              <Tab label="Email" value="email" />
              {email.attachments.map((attachment: IAttachmentRecord, index: number) => (
                <Tab key={index} label={attachment.filename} value={`attachment::${attachment.id}`} />
              ))}
            </Tabs>
          </Box>
          <TabPanel value="email" sx={{ height: "calc(100% - 48px)", p: 2, overflowY: "auto" }}>
            <EmailBody email={email} />
          </TabPanel>
          {email.attachments.map((attachment: any, index: number) => (
            <TabPanel key={index} value={`attachment::${attachment.id}`} sx={{ height: "calc(100% - 48px)", p: 0 }}>
              <PDFViewer fileUrl={attachment.presigned_url} activeArea={activeArea} />
            </TabPanel>
          ))}
        </TabContext>
      </Paper>
    </div>
  );
}

interface EmailBodyProps {
  email: any;
}

function EmailBody({ email }: EmailBodyProps) {
  return (
    <>
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold" }}>
        Da:{" "}
        <Typography variant="body2" sx={{ display: "inline" }}>
          {email.sender}
        </Typography>
      </Typography>
      {email.original_sender && (
        <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold" }}>
          Mittente originario:{" "}
          <Typography variant="body2" sx={{ display: "inline" }}>
            {email.original_sender}
          </Typography>
        </Typography>
      )}
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold", lineHeight: 1.5 }}>
        A:{" "}
        <Typography variant="body2" sx={{ display: "inline" }}>
          {email.recipient}
        </Typography>
      </Typography>
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold", lineHeight: 1.5 }}>
        Oggetto:{" "}
        <Typography
          variant="body2"
          sx={{ display: "inline" }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.subject) }}
        />
      </Typography>
      <Typography variant="body2" component="p" sx={{ mb: 1, fontWeight: "bold", lineHeight: 2 }}>
        Corpo:{" "}
        <Typography
          variant="body2"
          sx={{ display: "inline", lineHeight: 1 }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.body_html) }}
        />
      </Typography>
    </>
  );
}

interface EmailDetailsProps {
  email: any;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isPatching: boolean;
  isFetching: boolean;
  onSubmit: (data: any) => void;
  handleTarget: (target: HighlightAreaWithLabel) => void;
  page_idx: number;
  tabID: string;
  src_ref: { type: string; id: string };
}

function EmailDetails({
  email,
  value,
  setValue,
  isPatching,
  isFetching,
  onSubmit,
  handleTarget,
  page_idx,
  tabID,
  src_ref,
}: EmailDetailsProps) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", px: 1 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={(_, newValue) => setValue(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable tabs"
            >
              <Tab label="Dati estratti" value="1" />
              <Tab label="Avvisi" value="2" />
              <Tab label="Esporta codice" value="3" />
              <Tab label="Artifacts" value="4" />
            </Tabs>
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            <TabPanel value="1" sx={{ height: "100%", p: 0 }}>
              <EmailEditableFields
                key={email.id}
                email={email}
                isLoading={isPatching || isFetching}
                onSubmit={onSubmit}
                onTarget={handleTarget}
                page_idx={page_idx}
                tabID={tabID}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ height: "100%", p: 0 }}>
              <EmailExtractionInfo events={email.events} />
            </TabPanel>
            <TabPanel value="3" sx={{ p: 0 }}>
              <Box sx={{ p: 3 }}>
                <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                  <JsonView
                    src={email.parsed_info}
                    collapsed={2}
                    collapseStringMode="directly"
                    collapseStringsAfterLength={30}
                  />
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel value="4" sx={{ p: 0 }}>
              <ArtifactListPage artifacts={email.artifacts} />
            </TabPanel>
          </Box>
        </Box>
      </TabContext>
    </div>
  );
}

export default withAuth(EmailDetailPage);
