import { useAuthorization } from "@core/auth/authz/useAuthorization";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ContactsIcon from "@mui/icons-material/Contacts";
import RouteIcon from '@mui/icons-material/Route';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SpeedIcon from "@mui/icons-material/Speed";
import SyncIcon from "@mui/icons-material/Sync";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import PlaceIcon from '@mui/icons-material/Place';
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as AppLogo } from "../../assets/app_logo.svg";
import useAuthContext from "../auth/AuthContext";
import { useDevSettings } from "../debug/devSettings/DevSettings";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#121827",
  color: "#e1e1e1",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#121827",
  color: "#e1e1e1",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(3),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface INavigationDrawerProps {
  children: React.ReactNode;
}

export default function NavigationDrawer({ children }: INavigationDrawerProps) {
  const [open, setOpen] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { settings } = useDevSettings();
  const { can } = useAuthorization();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex", width: "100vw", height: "100vh", overflow: "hidden" }}>
      <Drawer variant="permanent" open={open}>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={handleDrawerToggle}
            sx={{
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              py: 1.5,
              fontWeight: "bold",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "#fff" }}>
                <AppLogo />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={t("Typelens")}
              sx={{
                opacity: open ? 1 : 0,
              }}
            />
          </ListItemButton>
        </ListItem>

        <Stack direction="column" justifyContent="space-between" height="100%">
          {/* Top list */}
          <List>
            {/* {user &&
              drawerItem(
                <DashboardOutlinedIcon sx={{ color: "#e1e1e1" }} />,
                t("Dashboard"),
                "/"
              )} */}
            {user && drawerItem(<RouteIcon sx={{ color: "#e1e1e1" }} />, "Flussi di lavoro", "/")}
            {/* {user &&
              drawerItem(
                <ContactEmergencyOutlinedIcon sx={{ color: "#e1e1e1" }} />,
                "Anagrafica clienti",
                "/upload?type=customers"
              )} */}
            {user && can("view", "ADDRESS_PAGE") &&
              drawerItem(
                <PlaceIcon sx={{ color: "#e1e1e1" }} />,
                "Indirizzi",
                "/addresses"
              )}
            {user &&
              can("view", "PRODUCTS_PAGE") &&
              drawerItem(<CategoryOutlinedIcon sx={{ color: "#e1e1e1" }} />, "Articoli", "/products")}
            {user &&
              can("view", "CONTACTS_PAGE") &&
              drawerItem(<ContactsIcon sx={{ color: "#e1e1e1" }} />, "Clienti", "/contacts")}
            {user &&
              can("view", "SYNC_PAGE") &&
              drawerItem(<SyncIcon sx={{ color: "#e1e1e1" }} />, "Sincronizzazione", "/sync")}

            {/* Sheet import page */}
            {user &&
              can("VIEW", "SHEET_IMPORT_PAGE") &&
              drawerItem(<CloudUploadIcon sx={{ color: "#e1e1e1" }} />, "Importa anagrafica", "/sheet_import")}

            {/* Benchmark page */}
            {settings.showBenchmark &&
              drawerItem(<SpeedIcon sx={{ color: "#e1e1e1" }} />, t("Benchmark"), "/benchmarks")}
            {/* Show debug page on development env */}
            {settings.showDebug &&
              drawerItem(<BugReportOutlinedIcon sx={{ color: "#e1e1e1" }} />, t("Debug"), "/debug")}
          </List>
          {/* Bottom list */}
          <List>
            {/* If user is logged in, show avatar info */}
            {user
              ? drawerItem(
                <AccountCircleOutlinedIcon sx={{ color: "#e1e1e1" }} />,
                user.display_name.length > 12 ? `${user.display_name.slice(0, 12)}...` : user.display_name,
                "/profile",
              )
              : drawerItem(<LoginOutlinedIcon sx={{ color: "#e1e1e1" }} />, t("Login"), "/login")}
            {drawerItem(
              <HelpOutlineIcon sx={{ color: "#e1e1e1" }} />,
              t("Contact us"),
              "https://www.mastranet.ai/contact-us",
            )}
            {user && drawerItem(<SettingsOutlinedIcon sx={{ color: "#e1e1e1" }} />, t("Settings"), "/settings")}
            {drawerItem(
              open ? (
                <KeyboardDoubleArrowLeftIcon sx={{ color: "#e1e1e1" }} />
              ) : (
                <KeyboardDoubleArrowRightIcon sx={{ color: "#e1e1e1" }} />
              ),
              open ? t("Collapse") : t("Expand"),
              undefined,
              handleDrawerToggle,
            )}
          </List>
        </Stack>
      </Drawer>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          px: 2,
          pt: 3,
          pb: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );

  function drawerItem(icon: JSX.Element, text: string, url?: string, onClick?: () => void): JSX.Element {
    // Is active if the current location matches the url prefix
    const isActive = url && location.pathname.startsWith(url);

    const handleNavigate = (url: string) => {
      // If the url is an external link, open it in a new tab
      if (url.startsWith("http")) {
        window.open(url, "_blank");
      } else {
        navigate(url);
      }
    };

    return (
      <ListItem key={text} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={onClick || !url ? onClick : () => handleNavigate(url)}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            fontWeight: isActive ? "bold" : "normal",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={text}
            sx={{
              opacity: open ? 1 : 0,
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  }
}
