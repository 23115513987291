import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { skipToken } from "@reduxjs/toolkit/query";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateUserMutation, useGetUserQuery, useUpdateUserMutation } from "../../app/services/appApi";
import { IUserCreate, IUserRead, IUserUpdate } from "../auth/types";

const DEFAULT_CREATE_VALUES: IUserCreate = {
  username: "",
  display_name: "",
  organization_id: "",
  password: "",
};

const cleanReadUserDataForUpdate = (user: IUserRead): IUserUpdate => {
  const { id, created_at, updated_at, organization, authz_sub, ...rest } = user;
  return { password: "", organization_id: organization.id, ...rest };
};

interface IUserFormDialogProps {
  userId?: IUserRead["id"];
  isNewUser?: boolean;
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing users.
 * It can be opened in two modes:
 * 1. Create mode: userId is not given
 * 2. Edit mode: userId is given
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */
const UserFormDialog: React.FC<IUserFormDialogProps> = ({ userId, isNewUser, render }) => {
  const [open, setOpen] = useState(false);

  const createMode: boolean = !userId;

  const form = useForm<IUserCreate | IUserUpdate>();

  // Delay data fetching until userId is defined and dialog is opened
  const { data: user, isLoading: isDataLoading } = useGetUserQuery(userId ?? skipToken, { skip: !open });

  const [triggerCreate, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateUserMutation();
  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateUserMutation();

  const isLoading = isCreateLoading || isUpdateLoading || isDataLoading;

  React.useEffect(() => {
    if (user) {
      form.reset(cleanReadUserDataForUpdate(user));
    } else {
      form.reset(DEFAULT_CREATE_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const sendData = (data: IUserCreate | IUserUpdate) => {
    if (createMode) {
      triggerCreate(data as IUserCreate);
    } else {
      triggerUpdate({
        id: userId || -1,
        ...data,
      });
    }
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar("Errore creazione", { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar("Creato con successo", { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornato con successo", { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? "Crea nuovo utente" : "Modifica utente"}</DialogTitle>
        {!isDataLoading ? (
          <form onSubmit={form.handleSubmit(sendData)}>
            <DialogContent>
              <Stack gap={2}>
                <Controller
                  name="username"
                  rules={{ required: true, minLength: 3 }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      autoFocus
                      label={"Username"}
                      type="text"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="display_name"
                  rules={{ required: false, minLength: 4 }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      autoFocus
                      label={"Nome visualizzato"}
                      type="text"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={!!fieldState.error && "Il campo deve contenere almeno 4 caratteri"}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="password"
                  rules={{ required: isNewUser }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={isNewUser ? "Password" : "Password (lasciare vuoto per mantenere invariata)"}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      required={isNewUser}
                      error={!!fieldState.error}
                      helperText={
                        !!fieldState.error
                          ? isNewUser
                            ? "Il campo è obbligatorio"
                            : "La password deve essere vuota o valida"
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="organization_id"
                  rules={{ required: true }}
                  control={form.control}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label={"ID Organizzazione"}
                      type="text"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={!!fieldState.error && "Il campo è obbligatorio"}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ m: 2 }}>
              <Button startIcon={<CloseOutlinedIcon />} onClick={handleClose}>
                Annulla
              </Button>
              <LoadingButton
                startIcon={<CheckOutlinedIcon />}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                type="submit"
              >
                {createMode ? "Crea" : "Aggiorna"}
              </LoadingButton>
            </DialogActions>
          </form>
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
export default UserFormDialog;
