// PDFViewer.tsx
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { ToolbarProps, defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { HighlightArea, RenderHighlightsProps, Trigger, highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import React, { ReactElement, useEffect, useState } from "react";

const workerUrl = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

interface HighlightAreaWithLabel extends HighlightArea {
  label: string;
}

interface PDFViewerProps {
  fileUrl: string;
  onDocumentLoad?: (doc: any) => void;
  activeArea?: HighlightAreaWithLabel | null;
  changeLabel?: boolean;
  onJumpToHighlightArea?: (highlightArea: HighlightArea) => void;
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  fileUrl,
  onDocumentLoad,
  activeArea,
  changeLabel = false,
  onJumpToHighlightArea,
}) => {
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
              <GoToPreviousPage />
              <div style={{ padding: "0px 2px", display: "flex", alignItems: "center" }}>
                <CurrentPageInput /> / <NumberOfPages />
              </div>
              <GoToNextPage />
            </div>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Download />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const [localActiveArea, setlocalActiveArea] = useState<HighlightAreaWithLabel | null>(activeArea ?? null);

  useEffect(() => {
    setlocalActiveArea(activeArea ?? null);
  }, [activeArea]);

  useEffect(() => {
    setlocalActiveArea(null);
    setIsDocumentLoaded(true);
  }, [fileUrl]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  const renderHighlights = (props: RenderHighlightsProps) => {
    if (props.pageIndex === localActiveArea?.pageIndex) {
      return (
        <div>
          <div
            style={Object.assign(
              {},
              {
                padding: "2px 6px",
                backgroundColor: "rgba(255, 0, 0, 0.5)",
                color: "white",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "4px",
                transform: changeLabel ? "translate(0, +140%)" : "translate(0, -120%)",
                top: props.getCssProperties(localActiveArea, props.rotation).top,
                left: props.getCssProperties(localActiveArea, props.rotation).left,
                position: props.getCssProperties(localActiveArea, props.rotation).position,
              },
            )}
          >
            {localActiveArea?.label}
          </div>
          <div
            className="highlight-area"
            style={Object.assign(
              {},
              {
                border: "1px solid rgba(255, 0, 0, 0.7)",
                backgroundColor: "rgba(255, 0, 0, 0.2)",
                borderRadius: "8px",
              },
              props.getCssProperties(localActiveArea, props.rotation),
            )}
          />
        </div>
      );
    }
    return <></>;
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  useEffect(() => {
    if (localActiveArea && isDocumentLoaded) {
      const slightlyHigherTop: HighlightArea = {
        ...localActiveArea,
        top: localActiveArea.top > 10 ? localActiveArea.top - 10 : 0,
      };
      highlightPluginInstance.jumpToHighlightArea(slightlyHigherTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localActiveArea, isDocumentLoaded]);

  return (
    // TODO: according to docs, the Worker component should be placed at the top level of the app
    <Worker workerUrl={workerUrl}>
      <Viewer
        fileUrl={fileUrl}
        plugins={[highlightPluginInstance, defaultLayoutPluginInstance]}
        // defaultScale={SpecialZoomLevel.PageWidth}
        onDocumentLoad={(doc) => {
          setIsDocumentLoaded(true);
          onDocumentLoad && onDocumentLoad(doc);
        }}
      />
    </Worker>
  );
};

export default PDFViewer;
