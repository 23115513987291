import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import React from "react";
import { useDevSettings } from "./DevSettings";

const DevSettingsForm: React.FC = () => {
  const { settings, updateSetting } = useDevSettings();

  const handleToggle = (key: keyof typeof settings) => (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSetting(key, event.target.checked);
  };

  return (
    <div>
      <FormGroup title="Dev settings">
        {Object.keys(settings).map((key) => (
          <FormControlLabel
            key={key}
            control={
              <Switch
                checked={settings[key as keyof typeof settings]}
                onChange={handleToggle(key as keyof typeof settings)}
                name={key}
                color="primary"
              />
            }
            label={key}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default DevSettingsForm;
