import { SearchUISchemaField } from "@features/email/dynamicUi/dynamicUiTypes";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

export interface IAutocompleteOption {
  id: string;
  option_text: string;
  option_details?: string[];
  metadata?: unknown;
}

export interface IAutocompleteRequest {
  id?: string;
  search?: string;
}

export interface IAutocompleteResponse {
  results: IAutocompleteOption[];
}

interface Props {
  advancedSearchParameters?: SearchUISchemaField["advancedSearchParameters"];
  disabled?: boolean;
  inputProps?: React.ComponentProps<typeof TextField>;
  label: string;
  lazyQuery: any;
  onChange: (value: string, metadata?: unknown) => void;
  searchModel: string;
  value: string;
}

const ServerSideSearchSelect2 = forwardRef<HTMLDivElement, Props>(
  ({ advancedSearchParameters, disabled, inputProps, label, lazyQuery, onChange, searchModel, value }, ref) => {
    const form = useFormContext();

    const [options, setOptions] = useState<IAutocompleteOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<IAutocompleteOption | null>(null);

    const extraParameters = useMemo(() => {
      if (advancedSearchParameters && advancedSearchParameters.length > 0) {
        return advancedSearchParameters.reduce(
          (acc, curr) => {
            const value = form.getValues(`${curr.valueFromField}.value`);

            if (value) {
              acc[curr.key] = value;
            }

            return acc;
          },
          {} as Record<string, any>,
        );
      }

      return {};
    }, [advancedSearchParameters, form]);

    const [trigger, { data, isFetching }] = lazyQuery();

    const debouncedTrigger = debounce(trigger, 300);

    // When component mounts, if value is not empty, fetch the selected option
    useEffect(() => {
      if (value && value !== selectedOption?.id) {
        trigger({ searchModel, params: { id: value } });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update options when data changes
    useEffect(() => {
      if (data) {
        const typedResults = (data.results ?? []) as IAutocompleteOption[];
        const uniqueResults = typedResults.filter(
          (option, index, arr) => index === arr.findIndex((t) => t.id === option.id),
        );

        setOptions(uniqueResults);
      }
    }, [data]);

    // When value or options change, update selectedOption
    useEffect(() => {
      if (value === undefined) {
        console.error(
          "ServerSideSearchSelect value is undefined, this should not happen. Check that form is not loading before default values are set.",
        );
      } else if (value === "") {
        // Value is empty, reset selectedOption. This can happen when user clears the input field or the pre-selected option is empty.
        // console.log("Value is empty, reset selectedOption");
        onChange(""); // Make sure the parent component is aware of the change
        setSelectedOption(null);
      } else {
        // Value is set, update selectedOption if necessary
        const foundSelection = options.find((x: IAutocompleteOption) => x.id === value);
        if (foundSelection && selectedOption?.id !== foundSelection.id) {
          // console.log("Selection changed, update", foundSelection);
          setSelectedOption(foundSelection);
          onChange(foundSelection.id, foundSelection.metadata);
        } else if (!foundSelection && !selectedOption) {
          // This is the case where the pre-selected option is not found (e.g. deleted from the database)
          // console.warn("Pre-selected option not found, this can happen if the option was deleted from the database. Reset value.");
          onChange("");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, options]);

    // Handle user typing in the search box
    const handleInputChange = useCallback(
      (_: any, value: string) => {
        if (value !== "") {
          debouncedTrigger({ searchModel, params: { ...extraParameters, search: value } });
        } else {
          setOptions([]);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchModel, extraParameters],
    );

    // Handle user selecting an option
    const handleChange = useCallback(
      (_: any, selectedOption: IAutocompleteOption | null) => {
        if (selectedOption) {
          onChange(selectedOption.id, selectedOption.metadata);
        } else {
          onChange("");
        }
      },
      [onChange],
    );

    const renderOption = (props: any, option: IAutocompleteOption) => (
      <li {...props} key={`listItem-${option.id}`}>
        <Box>
          <Typography variant="body1">{option.option_text}</Typography>
          {option.option_details &&
            option.option_details.map((val) => (
              <Typography variant="caption" key={val} display="block">
                {val}
              </Typography>
            ))}
        </Box>
      </li>
    );

    return (
      <Autocomplete
        ref={ref}
        value={selectedOption}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.option_text}
        options={options}
        filterOptions={(x) => x} // Let the server handle filtering
        loading={isFetching}
        onInputChange={handleInputChange}
        onChange={handleChange}
        noOptionsText="Nessun risultato"
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            label={label}
            disabled={disabled || inputProps?.disabled}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={renderOption}
      />
    );
  },
);

export default ServerSideSearchSelect2;
