import { Alert, Box, Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import Stack from "@mui/system/Stack";
import { useState } from "react";
import { useDevSettings } from "../debug/devSettings/DevSettings";
import { IEmailEvent } from "./emailTypes";

const EmailExtractionInfo = ({ events }: { events: IEmailEvent[] }) => {
  const [selectedLevels, setSelectedLevels] = useState(["SUCCESS", "INFO", "WARNING", "ERROR"]);
  const { settings } = useDevSettings();

  const getSeverity = (level: string) => {
    switch (level) {
      case "SUCCESS":
        return "success";
      case "INFO":
        return "info";
      case "WARNING":
        return "warning";
      case "ERROR":
        return "error";
      case "DEBUG":
        return "info";
      default:
        return "info";
    }
  };

  const handleLevelChange = (level: string) => {
    setSelectedLevels((prev) => (prev.includes(level) ? prev.filter((l) => l !== level) : [...prev, level]));
  };

  const filteredEvents = events.filter((event) => selectedLevels.includes(event.level));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Stack
        spacing={1}
        sx={{
          overflowY: "auto",
          flexGrow: 1,
          p: 1,
        }}
      >
        {filteredEvents.map((event, index) => (
          <Tooltip
            key={index}
            arrow
            placement="left"
            title={new Intl.DateTimeFormat("default", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(new Date(event.created_at))}
          >
            <Alert severity={getSeverity(event.level)} sx={{ py: 0 }}>
              {event.message}
            </Alert>
          </Tooltip>
        ))}
      </Stack>
      <Box sx={{ p: 1 }}>
        <FormGroup row>
          {["SUCCESS", "INFO", "WARNING", "ERROR", "DEBUG"]
            .filter((level) => level !== "DEBUG" || settings.showDebug) // Show DEBUG level only if enabled in dev settings
            .map((level) => (
              <FormControlLabel
                key={level}
                control={
                  <Checkbox
                    checked={selectedLevels.includes(level)}
                    onChange={() => handleLevelChange(level)}
                    size="small"
                  />
                }
                label={level}
              />
            ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default EmailExtractionInfo;
