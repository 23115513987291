import DeleteIcon from "@mui/icons-material/Delete";
import MemoryIcon from "@mui/icons-material/Memory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDeleteEmailMutation, useReprocessEmailMutation } from "../../app/services/appApi";

interface ITableRowActionsMenu {
  emailId: number;
  reprocessBtn: boolean;
  deleteBtn: boolean;
  isRegistered: boolean;
}

export default function TableRowActionsMenu({ emailId, reprocessBtn, deleteBtn, isRegistered }: ITableRowActionsMenu) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [deleteEmail, { isLoading: isDelLoading, isError: isDelError, isSuccess: isDelSuccess }] =
    useDeleteEmailMutation();
  const [reprocessEmail, { isLoading: isReprLoading, isError: isReprError, isSuccess: isReprSuccess }] =
    useReprocessEmailMutation();

  useEffect(() => {
    if (isDelError) {
      enqueueSnackbar("Impossibile eliminare l'email", { variant: "error" });
    } else if (isDelSuccess) {
      enqueueSnackbar("Email eliminata", { variant: "success" });
    }
  }, [isDelError, isDelSuccess]);

  useEffect(() => {
    if (isReprError) {
      enqueueSnackbar("Impossibile ri-processare l'email", { variant: "error" });
    } else if (isReprSuccess) {
      enqueueSnackbar("Riprocessamento avviato", { variant: "success" });
    }
  }, [isReprError, isReprSuccess]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GridActionsCellItem icon={<MoreVertIcon />} label="More " className="actions" onClick={handleMenuOpen} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuList>
          <MenuItem onClick={() => reprocessEmail(emailId)} disabled={isReprLoading || isRegistered || !reprocessBtn}>
            <ListItemIcon>
              <MemoryIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Riprocessa" />
          </MenuItem>
          <MenuItem onClick={() => deleteEmail(emailId)} disabled={isDelLoading || !deleteBtn}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Elimina" />
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
