import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCreateContactMutation, useGetContactQuery, useUpdateContactMutation } from "../../app/services/appApi";
import { IContactRecord, IContactRequest } from "./contactTypes";

interface IContactFormDialogProps {
  contactId?: IContactRecord["id"];
  render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing contacts.
 * It can be opened in two modes:
 * 1. Create mode: contactId is not given
 * 2. Edit mode: contactId is given
 *
 * In edit mode, the contact data is fetched from the API so
 * that the form can be pre-filled with the existing contact data.
 */
const ContactFormDialog: React.FC<IContactFormDialogProps> = ({ contactId, render }) => {
  const [open, setOpen] = useState(false);
  const createMode: boolean = !contactId;

  // Delay data fetching until dialog is opened
  const { data: contact } = useGetContactQuery(contactId || -1, { skip: createMode || !open });

  const [triggerCreate, { isLoading: isCreateLoading, isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateContactMutation();
  const [triggerUpdate, { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateContactMutation();

  // defaultValues are only available after contact data is fetched.
  // If contact data is not fetched, but contactId is given, show loading indicator
  // If contact data is not fetched and contactId is not given, show empty form
  const defaultContactValues: IContactRequest | undefined =
    // In create mode, show empty form without fetching contact data
    createMode
      ? { code: "", vat: "", name: "" }
      : // In edit mode show loading until contact data is fetched
        contact
        ? // Contact is fetched, show contact data
          {
            code: contact.code || "",
            name: contact.name || "",
            vat: contact.vat || "",
            // tag_names: contact.tags.map(tag => tag.name)
          }
        : // Contact is not fetched
          undefined;

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data: IContactRequest) => {
    if (createMode) {
      triggerCreate(data);
    } else {
      triggerUpdate({
        id: contactId || -1,
        ...data,
      });
    }
  };

  // Handle error and success notifications
  useEffect(() => {
    if (isCreateError) {
      enqueueSnackbar("Errore creazione", { variant: "error" });
    }
    if (isCreateSuccess) {
      enqueueSnackbar("Creato con successo", { variant: "success" });
      handleClose();
    }
    if (isUpdateError) {
      enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
    }
    if (isUpdateSuccess) {
      enqueueSnackbar("Aggiornato con successo", { variant: "success" });
      handleClose();
    }
  }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

  const openButton = render ? (
    render({ onClick: () => setOpen(true) })
  ) : (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  );

  return (
    <>
      {openButton}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{createMode ? "Crea nuovo cliente" : "Modifica cliente"}</DialogTitle>
        {defaultContactValues ? (
          <ContactForm
            createMode={createMode}
            isLoading={isCreateLoading || isUpdateLoading}
            onSubmit={handleSubmit}
            onClose={handleClose}
            defaultValues={defaultContactValues}
          />
        ) : (
          <DialogContent>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

interface IContactFormProps {
  defaultValues: IContactRequest;
  createMode?: boolean;
  isLoading: boolean;
  onSubmit: (data: IContactRequest) => void;
  onClose: () => void;
}

/**
 * Inner form component for ContactFormDialog.
 * Separate component is used to render only when the
 * defaultValues are available in case of edit mode.
 */
const ContactForm: React.FC<IContactFormProps> = ({ defaultValues, onSubmit, onClose, isLoading, createMode }) => {
  const { control, handleSubmit } = useForm<IContactRequest>({ defaultValues });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Stack gap={2}>
          <Controller
            name="code"
            rules={{ required: false, minLength: 3 }}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label={"cod. Cliente"}
                type="text"
                fullWidth
                error={!!fieldState.error}
                helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                {...field}
              />
            )}
          />
          <Controller
            name="vat"
            rules={{ required: false, minLength: 3 }}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label={"Partita IVA"}
                type="text"
                fullWidth
                error={!!fieldState.error}
                helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                {...field}
              />
            )}
          />
          <Controller
            name="name"
            rules={{ required: true, minLength: 3 }}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                label={"Nome cliente"}
                type="text"
                fullWidth
                required
                error={!!fieldState.error}
                helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                {...field}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Button startIcon={<CloseOutlinedIcon />} onClick={onClose}>
          Annulla
        </Button>
        <LoadingButton
          startIcon={<CheckOutlinedIcon />}
          loading={isLoading}
          disabled={isLoading}
          variant="contained"
          type="submit"
        >
          {createMode ? "Crea" : "Aggiorna"}
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

export default ContactFormDialog;
