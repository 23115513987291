import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function LoadingPlaceholder() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{
        width: "100%",
        height: "75%",
      }}
    >
      <CircularProgress />
      <Typography variant="h6" component="h1" noWrap sx={{ ml: 2 }}>
        <b>Caricamento...</b>
      </Typography>
    </Stack>
  );
}
