import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import { Box, Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/system/Stack";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteOrganizationMutation, useGetOrganizationsQuery } from "../../app/services/appApi";
import { withAuth } from "../auth/authWrappers";
import { IOrganizationRead } from "../auth/types";
import OrganizationFormDialog from "./OrganizationFormDialog";
import OrganizationSearchBar from "./OrganizationSearchBar";

const OrganizationListPage = () => {
  const navigate = useNavigate();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetOrganizationsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    search: searchValue,
  });

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      width: 180,
    },
    {
      field: "name",
      headerName: "Name",
      width: 350,
    },
    {
      field: "is_deactivated",
      headerName: "Is Deactivated",
      flex: 1,
    },
    {
      field: "edit",
      headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => <OrganizationFormDialog organizationId={params.row.id} />,
    },
    {
      field: "delete",
      headerName: "",
      //@ts-ignore-next-line
      renderCell: (params: GridValueGetterParams) => <OrganizationDeleteBtn organizationId={params.row.id} />,
    },
  ];

  return (
    <>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FactoryIcon />
          <Typography variant="h5" component="h1" noWrap>
            <b> Organizzazioni</b>
          </Typography>
        </Box>
        <Stack direction="row" gap={2} alignItems="center">
          <OrganizationSearchBar onSearch={setSearchValue} size="small" />
          <OrganizationFormDialog
            render={(props) => (
              <Button startIcon={<AddIcon />} variant="contained" color="primary" {...props}>
                Aggiungi
              </Button>
            )}
          />
        </Stack>
      </Stack>

      <DataGrid
        disableRowSelectionOnClick
        rows={data?.results || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ flex: 1 }}
        loading={isLoading}
        paginationMode="server"
        rowCount={data?.count || 0}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </>
  );
};

const OrganizationDeleteBtn: React.FC<{ organizationId: IOrganizationRead["id"] }> = ({ organizationId }) => {
  const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteOrganizationMutation();
  const { t } = useTranslation();

  // Handle error and success notifications
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(t("Model deleted", { model: "Organization", count: 1 }), { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar(t("Model deletion failed", { model: "Organization", count: 1 }), { variant: "error" });
    }
  }, [isSuccess, isError, t]);

  return (
    <IconButton onClick={() => triggerDelete(organizationId)} color="error" disabled={isLoading}>
      <DeleteOutlineOutlinedIcon />
    </IconButton>
  );
};

export default withAuth(OrganizationListPage);
