import { Box, Button, CircularProgress, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSheetImportStatusQuery, useTriggerSheetImportMutation } from "../../app/services/appApi";
import { SheetImportProgress } from "./sheetImportTypes";

type ProgressType =
  | SheetImportProgress["addresses"]
  | SheetImportProgress["contacts"]
  | SheetImportProgress["products"];

const ProgressBar: React.FC<{ progress: ProgressType; label: string }> = ({ progress, label }) => {
  const percentage = progress.total > 0 ? (progress.current / progress.total) * 100 : 0;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2">{label}</Typography>
      <LinearProgress variant="determinate" value={percentage} sx={{ mt: 1 }} />
      <Typography variant="caption">{`${Math.round(percentage)}% (${progress.current}/${progress.total})`}</Typography>
      <Typography variant="caption" display="block">
        {`Created: ${progress.created}, Updated: ${progress.updated}, Deleted: ${progress.deleted}`}
      </Typography>
    </Box>
  );
};

const SheetImportComponent: React.FC = () => {
  const { t } = useTranslation();
  const [triggerImport, { isLoading: isImportTriggering }] = useTriggerSheetImportMutation();
  const [taskId, setTaskId] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const { data: importStatus, isLoading: isImportStatusLoading } = useGetSheetImportStatusQuery(
    { task_id: taskId || "" },
    { skip: !taskId, pollingInterval: 1000 },
  );

  useEffect(() => {
    if (importStatus && importStatus.status === "SUCCESS") {
      setTaskId(null);
      setFile(null);
    }
  }, [importStatus]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      setFile(selectedFile);
    } else {
      alert("Please select a valid Excel (.xlsx) file.");
      event.target.value = "";
    }
  };

  const handleImportClick = async () => {
    if (!file) return;

    try {
      const result = await triggerImport(file).unwrap();
      setTaskId(result.task_id);
    } catch (error) {
      console.error("Failed to trigger import:", error);
    }
  };

  const isImporting = !!taskId;

  const getImportStatusMessage = () => {
    if (!importStatus) return "Initializing...";
    switch (importStatus.status) {
      case "IMPORTING":
        return "Importing data...";
      case "SUCCESS":
        return "Import completed successfully!";
      default:
        return "Import in progress...";
    }
  };

  return (
    <Box>
      <input
        accept=".xlsx"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
        disabled={isImportTriggering || isImporting}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" disabled={isImportTriggering || isImporting}>
          {t("Select Excel File")}
        </Button>
      </label>
      {file && (
        <Typography variant="body2" sx={{ mt: 1 }}>
          {file.name}
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={handleImportClick}
        disabled={!file || isImportTriggering || isImporting}
        sx={{ ml: 2 }}
        startIcon={isImportTriggering || isImporting ? <CircularProgress size={20} color="inherit" /> : null}
      >
        {t("Import")}
      </Button>
      {isImporting && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">{getImportStatusMessage()}</Typography>
          {importStatus && importStatus.progress && (
            <>
              <ProgressBar progress={importStatus.progress.products} label="Products" />
              <ProgressBar progress={importStatus.progress.contacts} label="Contacts" />
              <ProgressBar progress={importStatus.progress.addresses} label="Addresses" />
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SheetImportComponent;
