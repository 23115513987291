import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useDeleteEmailMutation } from "../../../app/services/appApi";
import { IconBtn } from "../../input/Btn";

type ButtonVariant = "text" | "outlined" | "contained" | "icon";

interface IDeleteEmailBtnProps extends Omit<React.ComponentProps<typeof Button>, "variant"> {
  ids: number[];
  variant?: ButtonVariant;
  onDeleteSuccess?: () => void;
}

export default function DeleteEmailBtn({ ids, variant = "icon", onDeleteSuccess, ...props }: IDeleteEmailBtnProps) {
  const [deleteEmail, { isLoading, isError, isSuccess }] = useDeleteEmailMutation();

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Impossibile eliminare l'email", { variant: "error" });
    } else if (isSuccess) {
      enqueueSnackbar("Email eliminata", { variant: "success" });
      onDeleteSuccess?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  if (ids.length >= 1) {
    return (
      <div>
        <IconBtn
          disabled={isLoading || ids.length === 0}
          //delete all the emails with the ids in the array
          onClick={() => ids.forEach((id) => deleteEmail(id))}
          color="error"
          askConfirm
          confirmMsg={
            ids.length > 1
              ? "Sei sicuro di voler eliminare queste email? Non sarà più possibile recuperarle."
              : "Sei sicuro di voler eliminare questa email? Non sarà più possibile recuperarla."
          }
        >
          <DeleteIcon />
        </IconBtn>
      </div>
    );
  }
}
