import InfoIcon from "@mui/icons-material/Info";
import { IconButton, List, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import React from "react";

interface MessagePopupProps {
  messages: string[];
}

const MessagePopup: React.FC<MessagePopupProps> = ({ messages }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton size="small" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} sx={{ ml: 1 }}>
        <InfoIcon color="action" fontSize="small" />
      </IconButton>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>Messages:</Typography>
        <List dense>
          {messages.map((message, index) => (
            <ListItem key={index}>
              <ListItemText primary={`• ${message}`} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default MessagePopup;
