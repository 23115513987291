import AddIcon from "@mui/icons-material/Add";
import RouteIcon from '@mui/icons-material/Route';
import ListAltIcon from "@mui/icons-material/ListAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetWorkflowsQuery } from "../../app/services/appApi";
import { withAuth } from "../auth/authWrappers";
import { IWorkflowRead } from "../auth/types";

const WorkflowCard = ({ workflow }: { workflow: IWorkflowRead }) => {
  const navigate = useNavigate();
  const handleEditClick = (id: number) => {
    navigate(`/workflow/${id}`);
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const { id, code, count_emails, count_emails_current_month, count_emails_last_24h, title, subtitle, ratelimit } =
  workflow;

  const progressPercentage = Math.min((count_emails_current_month / ratelimit) * 100, 100);

  return (
    <>
      <Grid item xs={12} md={6} lg={4}>
        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CardContent sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <ListAltIcon style={{ width: 36, height: 36, marginRight: 8 }} />
            <Typography variant="h4" component="div">
              {title}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {subtitle}
            </Typography>
            <Typography variant="body2">Codice: {code}</Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Totale: {count_emails} | Mese corrente: {count_emails_current_month}/{ratelimit} | 24 ore:{" "}
              {count_emails_last_24h}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              <BorderLinearProgress variant="determinate" value={progressPercentage} />
            </Typography>
            <Button
              variant="outlined"
              onClick={() => handleEditClick(id)}
              startIcon={<VisibilityIcon />}
              sx={{ width: "150px", height: "40px", marginTop: "auto", alignSelf: "flex-end" }}
            >
              Visualizza
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const WorkflowListPage = () => {
  const { data, isLoading } = useGetWorkflowsQuery({});
  const rows = data?.results || [];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack justifyContent="space-between" gap={2} direction="row" sx={{ mb: 2 }}>
        <Typography variant="h5" component="h1" noWrap sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}>
          <RouteIcon />
          <b> Flussi di lavoro</b>
        </Typography>
        <Tooltip title="Non hai i permessi per aggiungere nuovi flussi di lavoro" arrow>
          <span>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              disabled
              sx={{ width: "130px", height: "40px" }}
            >
              AGGIUNGI
            </Button>
          </span>
        </Tooltip>
      </Stack>
      <Grid container spacing={3}>
        {rows.map((workflow) => (
          <WorkflowCard key={workflow.id} workflow={workflow} />
        ))}
      </Grid>
    </Box>
  );
};

export default withAuth(WorkflowListPage);
