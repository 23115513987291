import { Box, Paper, Typography } from "@mui/material";
import React, { forwardRef, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.4.168/build/pdf.worker.mjs";

const PDF_URL = "https://pdfobject.com/pdf/sample.pdf"; // Set this to an empty string

interface IPdfDocRendererProps {
  onFileSelect: (file: File) => void;
  file: File | null;
  page: number;
  onPageChange: (pageNumber: number) => void;
  onMaxPageChange: (maxPage: number) => void;
  debugMode: boolean;
  onDimensionsChange: (width: number, height: number) => void;
  onFileNameChange?: (fileName: string) => void;
  scale: number;
}

const PdfDocRenderer = forwardRef<HTMLDivElement, IPdfDocRendererProps>(
  (
    { onFileSelect, file, onPageChange, debugMode, onMaxPageChange, page, onDimensionsChange, onFileNameChange, scale },
    ref,
  ) => {
    const [isDragging, setIsDragging] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [pdfSource, setPdfSource] = useState<string | File | null>(PDF_URL || null);

    const handleDrop = (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile && droppedFile.type === "application/pdf") {
        handleFileChange(droppedFile);
      }
    };

    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      onMaxPageChange(numPages);
      onPageChange(1);
    };

    const handlePageLoadSuccess = ({ width, height }: { width: number; height: number }) => {
      onDimensionsChange(width, height);
    };

    const handleFileChange = (selectedFile: File) => {
      if (selectedFile) {
        const fileName = selectedFile.name;
        if (fileName && fileName.length > 0) {
          onFileNameChange && onFileNameChange(fileName);
        }
        setPdfSource(selectedFile);
        onFileSelect(selectedFile);
        onPageChange(1);
      }
    };

    const renderPdfViewer = () => (
      <Document file={pdfSource} onLoadSuccess={handleDocumentLoadSuccess}>
        <Page
          key={`page_${page}_scale_${scale}`}
          pageNumber={page}
          onLoadSuccess={handlePageLoadSuccess}
          scale={scale}
        />
      </Document>
    );

    const renderDropZone = () => (
      <Paper
        sx={{
          height: "100%",
          border: isDragging ? "2px dashed #2196f3" : "2px dashed #ccc",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          overflow: "auto",
          position: "relative",
          padding: 2,
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={() => setIsDragging(false)}
        onDrop={handleDrop}
        onClick={() => inputRef.current?.click()}
      >
        <input
          type="file"
          ref={inputRef}
          hidden
          accept="application/pdf"
          onChange={(e) => {
            const selectedFile = e.target.files?.[0];
            if (selectedFile) {
              handleFileChange(selectedFile);
            }
          }}
        />
        <Typography variant="h6" gutterBottom>
          No PDF loaded
        </Typography>
        <Box>
          <Typography>Please upload a PDF file</Typography>
          <Typography variant="body2">Drop PDF here or click to select</Typography>
        </Box>
      </Paper>
    );

    return (
      <div ref={ref} style={{ width: "100%", height: "100%" }}>
        {pdfSource ? renderPdfViewer() : renderDropZone()}
      </div>
    );
  },
);

export default PdfDocRenderer;
