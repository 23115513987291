import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { useGetWorkflowQuery } from "../../app/services/appApi";

interface IEmailCopyBtnProps {
  workflowId: number;
}

const EmailCopyBtn: React.FC<IEmailCopyBtnProps> = (props) => {
  const { data } = useGetWorkflowQuery(props.workflowId);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    if (!navigator.clipboard) {
      enqueueSnackbar("Il tuo browser non supporta la copia negli appunti", { variant: "error" });
      return;
    }

    navigator.clipboard.writeText(data?.email_addr || "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  useEffect(() => {
    if (isCopied) {
      enqueueSnackbar("Email copiata negli appunti", { variant: "success" });
      setTimeout(() => setIsCopied(false), 3000);
    }
  }, [isCopied]);

  return (
    <TextField
      variant="outlined"
      disabled
      value={data?.email_addr || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailOutlined />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleCopyToClipboard} disabled={isCopied}>
              {isCopied ? <CheckIcon /> : <ContentCopyIcon />}{" "}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EmailCopyBtn;

function useGetWorkflow(workflowId: {}): { data: any } {
  throw new Error("Function not implemented.");
}
