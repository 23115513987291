import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { withAuth } from "../auth/authWrappers";
// SYnc icon
import SyncIcon from "@mui/icons-material/Sync";
import SyncAllComponent from "./SyncAllComponent";

const SyncPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="space-between" direction="column" spacing={2}>
      <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
        <b>Sincronizzazione</b>
      </Typography>

      <Stack spacing={3}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
          <SyncIcon />
          <Typography variant="body1">Sincronizza contatti e indirizzi con il CRM</Typography>
        </Stack>
        <Typography variant="body2">
          Questa operazione può richiedere del tempo. Non chiudere la pagina finché non è completata.
        </Typography>
        <SyncAllComponent />
      </Stack>
    </Stack>
  );
};

export default withAuth(SyncPage);
