import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import FastForwardIcon from "@mui/icons-material/FastForward";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import MemoryIcon from "@mui/icons-material/Memory";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Chip from "@mui/material/Chip";
import { IEmailRecord } from "./emailTypes";
export default function EmailStatusChip({
  level,
  variant,
}: {
  level: IEmailRecord["status"];
  variant?: "filled" | "outlined";
}) {
  const getStatusIcon = (level: string) => {
    switch (level) {
      case "FAILED":
        return <ErrorIcon />;
      case "PENDING":
        return <HourglassEmptyIcon />;
      case "PROCESSING":
        return <MemoryIcon />;
      case "PROCESSED":
        return <DoneIcon />;
      case "CANCELLED":
        return <NotInterestedIcon />;
      case "SKIPPED":
        return <FastForwardIcon />;
      default:
        return <ErrorIcon />;
    }
  };

  const getStatusMessage = (level: string) => {
    switch (level) {
      case "ERROR":
        return "Errore";
      case "PENDING":
        return "In attesa";
      case "PROCESSING":
        return "In elaborazione";
      case "PROCESSED":
        return "Processato";
      case "CANCELLED":
        return "Annullato";
      case "SKIPPED":
        return "Saltato";
      default:
        return "Errore";
    }
  };

  const getStatusColor = (level: string) => {
    switch (level) {
      case "ERROR":
        return "error";
      case "PENDING":
        return "warning";
      case "PROCESSING":
        return "warning";
      case "PROCESSED":
        return "success";
      case "CANCELLED":
        return "default";
      case "SKIPPED":
        return "default";
      default:
        return "error";
    }
  };

  const capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <Chip
      size="small"
      //@ts-ignore-next-line
      color={getStatusColor(level)}
      icon={getStatusIcon(level)}
      label={capitalize(getStatusMessage(level))}
      variant={variant}
    />
  );
}
