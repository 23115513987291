import PublishIcon from "@mui/icons-material/Publish";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { withAuth } from "../auth/authWrappers";
import SheetImportComponent from "./SheetImportComponent";

const SheetImportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="space-between" direction="column" spacing={2}>
      <Typography variant="h5" component="h1" noWrap sx={{ mb: 2 }}>
        <b>{t("Excel Sheet Import")}</b>
      </Typography>

      <Stack spacing={3}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
          <PublishIcon />
          <Typography variant="body1">{t("Import contacts and addresses from Excel sheet")}</Typography>
        </Stack>
        <Typography variant="body2">
          {t("This operation may take some time. Please do not close the page until it is completed.")}
        </Typography>
        <SheetImportComponent />
      </Stack>
    </Stack>
  );
};

export default withAuth(SheetImportPage);
