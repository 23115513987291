import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useGetEmailNavigationQuery } from "../../../app/services/appApi";
import { IEmailRecord } from "../emailTypes";

interface IEmailNavigation {
  email: IEmailRecord;
  action: "next" | "prev";
}

export default function EmailPrevNextBtn({ email, action }: IEmailNavigation) {
  const { data, isLoading } = useGetEmailNavigationQuery(email.id);

  const Icon = action === "next" ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
  const targetEmailId = action === "next" ? data?.next_email_id : data?.prev_email_id;

  return (
    <Tooltip title={action === "next" ? "Email successiva" : "Email precedente"}>
      <div>
        <IconButton
          color="primary"
          disabled={isLoading || targetEmailId === null}
          component={targetEmailId !== null ? Link : "button"}
          //TODO: This is a hack. find a better way to navigate
          to={targetEmailId !== null ? `/workflow/${email?.workflow?.id}/email/${targetEmailId}` : ""}
        >
          <Icon />
        </IconButton>
      </div>
    </Tooltip>
  );
}
