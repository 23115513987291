import { Resolver } from "react-hook-form";
import belliniOdaPlugin from "./belliniOda/belliniOdaPlugin";

export interface WorkflowPlugin {
  // The react-hook-form resolver for this workflow used to validate the form
  getResolver: () => Resolver | undefined;

  // vvv Add other plugin methods as needed vvv
}

// The default plugin to use when no plugin is found
const defaultPlugin: WorkflowPlugin = {
  getResolver: () => undefined,
  // Add other plugin methods as needed
};

// Contains all the available workflow plugins
const plugins: Record<string, WorkflowPlugin> = {
  bellini_oda: belliniOdaPlugin,
};

/**
 * Get the workflow plugin by name
 * @param WorkflowName The name of the workflow
 * @returns The workflow plugin
 */
export function getWorkflowPluginByName(workflowName: string | undefined | null): WorkflowPlugin {
  if (!workflowName) {
    return defaultPlugin;
  }

  return plugins[workflowName] || defaultPlugin;
}
