import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

// Define the shape of our settings
interface DevSettings {
  showIdColumn: boolean;
  showBenchmark: boolean;
  showDebug: boolean;
  // Add more settings as needed
}

// Define the shape of our context
interface DevSettingsContextType {
  settings: DevSettings;
  updateSetting: <K extends keyof DevSettings>(key: K, value: DevSettings[K]) => void;
}

const DevSettingsContext = createContext<DevSettingsContextType | undefined>(undefined);

export const useDevSettings = (): DevSettingsContextType => {
  const context = useContext(DevSettingsContext);
  if (!context) {
    throw new Error("useDevSettings must be used within a DevSettingsProvider");
  }
  return context;
};

interface DevSettingsProviderProps {
  children: ReactNode;
}

const defaultSettings: DevSettings = {
  showIdColumn: false,
  showBenchmark: false,
  showDebug: process.env.NODE_ENV === "development",
};

export const DevSettingsProvider: React.FC<DevSettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<DevSettings>(() => {
    const storedSettings = localStorage.getItem("devSettings");
    return storedSettings ? JSON.parse(storedSettings) : defaultSettings;
  });

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const storedSettings = localStorage.getItem("devSettings");
      if (storedSettings) {
        setSettings(JSON.parse(storedSettings));
      }
      setIsInitialized(true);
    } else {
      localStorage.setItem("devSettings", JSON.stringify(settings));
    }
  }, [settings, isInitialized]);

  const updateSetting = <K extends keyof DevSettings>(key: K, value: DevSettings[K]) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  return <DevSettingsContext.Provider value={{ settings, updateSetting }}>{children}</DevSettingsContext.Provider>;
};
