import { Button, Stack, Typography } from "@mui/material";

export function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <Stack spacing={2} direction="column" justifyContent="center" alignItems="center" sx={{ height: "60vh" }}>
      <h1>Qualcosa è andato storto</h1>
      <Typography variant="body1" align="center">
        Si è verificato un errore inaspettato. Il nostro team è stato informato e sta lavorando per risolvere il
        problema.
        <br />
        Se il problema persiste, contatta il nostro supporto.
      </Typography>
      <Button component="a" variant="contained" color="primary" href="/">
        Ricarica app
      </Button>
    </Stack>
  );
}
