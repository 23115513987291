import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { Tooltip } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useRegisterEmailMutation } from "../../app/services/appApi";
import { IEmailRecord } from "./emailTypes";

interface RegisterEmailBtnProps extends React.ComponentProps<typeof LoadingButton> {
  email: IEmailRecord;
  disable?: boolean;
}

function RegisterEmailBtn({ email, disable, ...props }: RegisterEmailBtnProps) {
  const [registerEmail, { isLoading, isSuccess, isError }] = useRegisterEmailMutation();

  //if the enable.register is equals to  0, the button will be disabled, otherwise it will be enabled
  const hasGateway = email.workflow?.enable_register;
  const message = !hasGateway
    ? "Non è collegato un gestionale, impossibile inviare i dati"
    : "Invia i dati al ERP o Gestionale";
  const disabled = !hasGateway || email.is_registered || disable || !email.is_certified;

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Dati inviati con successo", { variant: "success" });
    }
    if (isError) {
      enqueueSnackbar("Errore durante l'invio dei dati", { variant: "error" });
    }
  }, [isSuccess, isError]);

  return (
    <Tooltip title={message}>
      <span>
        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          {...props}
          onClick={() => registerEmail({ id: email.id })}
          loading={isLoading}
          disabled={disabled}
        >
          Invia
        </LoadingButton>
      </span>
    </Tooltip>
  );
}

export default RegisterEmailBtn;
