import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/system/Stack";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CertifyEmailBtn from "./CertifyEmailBtn";
import RegisterEmailBtn from "./RegisterEmailBtn";
import RegisteredEmailAlert from "./RegisteredEmailAlert";
import DynamicEmailForm from "./dynamicUi/DynamicEmailForm";
import { EmailViewer } from "./dynamicUi/FieldRenderer";
import { UISchema, UISchemaField } from "./dynamicUi/dynamicUiTypes";
import { getWorkflowPluginByName } from "./dynamicUi/plugins/workflowPlugin";
import { EmailParsedInfo, IEmailRecord } from "./emailTypes";

export interface EmailEditableFieldsProps {
  email: IEmailRecord;
  isLoading: boolean;
  onSubmit: (data: IEmailRecord) => void;
  onTarget: (target: EmailViewer) => void;
  page_idx: number;
  tabID: string;
}

const getFieldSchema = (uiSchema: UISchema, field: string, insideItems = false): UISchemaField | null => {
  if (insideItems) {
    return uiSchema.items.fields[field];
  }

  const groupName = Object.keys(uiSchema).find((groupName) => uiSchema[groupName].fields[field] !== undefined);

  if (!groupName) {
    return null;
  }

  return uiSchema[groupName].fields[field];
};

const sanitizeParsedInfo = (parsedInfo: EmailParsedInfo, uiSchema: UISchema, insideItems = false): EmailParsedInfo => {
  const sanitized: EmailParsedInfo = {};

  Object.keys(parsedInfo).forEach((key) => {
    // Manages at most 1 recursion level
    if (key === "items" && !insideItems) {
      const items = parsedInfo.items;

      if (!items) {
        return;
      }

      sanitized.items = items.map((item) => sanitizeParsedInfo(item, uiSchema, true));
    } else {
      sanitized[key] = { ...parsedInfo[key] };
      const value = parsedInfo[key].value;

      if (value === null || value === undefined) {
        return;
      }

      const schema = getFieldSchema(uiSchema, key, insideItems);

      if (!schema) {
        return;
      }

      switch (schema.type) {
        case "string":
        case "multiline": {
          sanitized[key].value = String(value);
          break;
        }
        case "int":
        case "float": {
          sanitized[key].value = !isNaN(Number(value)) ? Number(value) : null;
          break;
        }
        case "enum": {
          if (schema.multiple) {
            sanitized[key].value = Array.isArray(value)
              ? value.filter(Boolean).map(String)
              : typeof value === "string"
                ? [value]
                : [];

            // Check if the values are in the options
            sanitized[key].value = (sanitized[key].value as string[]).filter(
              (v: string) => schema.options.find((option) => option.value.toString() === v) !== undefined,
            );
          } else {
            sanitized[key].value = String(value);

            // Check if the value is in the options
            sanitized[key].value = schema.options.find((option) => option.value.toString() === sanitized[key].value)
              ? sanitized[key].value
              : null;
          }
          break;
        }
        case "search": {
          if (schema.multiple) {
            sanitized[key].value = Array.isArray(value)
              ? value.filter(Boolean).map(String)
              : typeof value === "string"
                ? [value]
                : [];
          } else {
            sanitized[key].value = String(value);
          }
          break;
        }
        case "date": {
          // TODO: We should also validate date formats
          break;
        }
      }
    }
  });

  return sanitized;
};

const EmailEditableFields: React.FC<EmailEditableFieldsProps> = ({
  email,
  isLoading,
  onSubmit,
  onTarget,
  page_idx,
  tabID,
}) => {
  const form = useForm<any>({
    defaultValues: sanitizeParsedInfo(email.parsed_info, email.ui_schema),
    resolver: getWorkflowPluginByName(email.workflow?.name).getResolver(),
  });

  useEffect(() => {
    form.reset(sanitizeParsedInfo(email.parsed_info, email.ui_schema));
  }, [email, form]);

  const [isDebug, setIsDebug] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleError = (errors: any) => {
    enqueueSnackbar("Ci sono errori nel form", { variant: "error" });
    console.error("Validation error: ", errors);
  };

  const disableAll = email.is_registered || isLoading;

  const handleSubmit = (data: any) => {
    // HACK: this is a workaround to avoid the issue with Joi schema that doesn't support PATCHing unknown fields via rtk-query
    const stringified = JSON.stringify(data, null, 2);
    const unStringified = JSON.parse(stringified);
    onSubmit(unStringified);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit, handleError)}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 1,
            p: 1,
          }}
        >
          {email.is_registered && <RegisteredEmailAlert sx={{ mb: 2 }} />}
          <DynamicEmailForm
            uiSchema={email.ui_schema}
            email={email}
            control={form.control}
            disabled={disableAll}
            onTarget={onTarget}
            page_idx={page_idx}
            tabID={tabID}
          />
        </Box>
        <Stack direction={{ xs: "column", sm: "row" }} gap={1} sx={{ mt: 2 }} justifyContent="flex-end">
          <Box sx={{ flexGrow: 1 }}>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isDebug} onChange={() => setIsDebug(!isDebug)} />}
                  label="Mostra campi avanzati"
                />
              </FormGroup>
            </MenuItem>
          </Menu>
          <Tooltip title="Salva i dati">
            <span>
              <LoadingButton
                type="submit"
                loading={isLoading}
                disabled={email.is_registered}
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
              >
                Salva
              </LoadingButton>
            </span>
          </Tooltip>
          <CertifyEmailBtn email={email} /> {/* disable={form.formState.isDirty} /> */}
          <RegisterEmailBtn email={email} /> {/* disable={form.formState.isDirty} /> //TODO: re-enable */}
        </Stack>
      </form>
    </FormProvider>
  );
};

export default EmailEditableFields;
