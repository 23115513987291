import { Box, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LabelSelector from "./LabelSelector";
import LabellerPageNavigator from "./LabellerPageNavigator";
import PageTagSelector from "./PageTagSelector";
import PdfDocRenderer from "./PdfDocRenderer";
import { BoundingBox, Label, PageTag } from "./labellerTypes";

interface ILabellerCanvasProps {
  value: BoundingBox[];
  labels: Label[];
  onChange: (boxes: BoundingBox[]) => void;
  tags: PageTag[];
  onTagsChange: (tags: PageTag[]) => void;
  originalFileName: string;
  setOriginalFileName: (fileName: string) => void;
}

const BBOX_W_THRESH = 1; // 1% of the width
const BBOX_H_THRESH = 1; // 1% of the height

const LabellerCanvas: React.FC<ILabellerCanvasProps> = ({
  value,
  onChange,
  labels,
  tags,
  onTagsChange,
  originalFileName,
  setOriginalFileName,
}) => {
  const [activeLabel, setActiveLabel] = useState<Label | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [pdfDimensions, setPdfDimensions] = useState<{ width: number; height: number } | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [debugMode, setDebugMode] = useState(false);
  const [activateDraw, setActivateDraw] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scale, setScale] = useState(1);
  const [resizingBox, setResizingBox] = useState<BoundingBox | null>(null);
  const [drawState, setDrawState] = useState<{
    isDrawing: boolean;
    isResizing: boolean;
    isDragging: boolean;
    startPoint: { x: number; y: number } | null;
    previewBox: BoundingBox | null;
    draggedBox: BoundingBox | null;
    dragOffset: { x: number; y: number };
    resizeStart: { x: number; y: number };
  }>({
    isDrawing: false,
    isResizing: false,
    isDragging: false,
    startPoint: null,
    previewBox: null,
    draggedBox: null,
    dragOffset: { x: 0, y: 0 },
    resizeStart: { x: 0, y: 0 },
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const drawingAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateDimensions = () => {
      const viewportHeight = window.innerHeight;
      const offsetTop = document.getElementById("pdf-container")?.offsetTop || 0;
      const newHeight = viewportHeight - offsetTop - 30; // 30px for bottom margin
      setContainerHeight(newHeight);
    };

    calculateDimensions();
    window.addEventListener("resize", calculateDimensions);
    return () => window.removeEventListener("resize", calculateDimensions);
  }, []);

  const handleDimensionsChange = (width: number, height: number) => {
    setPdfDimensions({ width, height });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!activeLabel || !activateDraw || !pdfDimensions) return;

    const rect = drawingAreaRef.current?.getBoundingClientRect();
    if (!rect) return;

    const startPoint = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };

    setDrawState({
      ...drawState,
      isDrawing: true,
      startPoint,
      previewBox: {
        id: "preview",
        label: activeLabel,
        x: startPoint.x,
        y: startPoint.y,
        width: 0,
        height: 0,
        page: currentPage,
      },
    });
  };

  // {/const handleResizeStart = (e: React.MouseEvent, box: BoundingBox) => {
  //     e.stopPropagation();
  //     e.preventDefault();

  //     setResizingBox(box);
  //     setDrawState({
  //         ...drawState,
  //         isResizing: true,
  //         resizeStart: {x: e.clientX, y: e.clientY},
  //     });

  //     console.log('Resizing started at ', e.clientX, e.clientY);

  //     const handleResize = (e: MouseEvent) => {
  //         if (!resizingBox || !pdfDimensions) return;

  //         const rect = drawingAreaRef.current?.getBoundingClientRect();
  //         if (!rect) return;

  //         const deltaX = e.clientX - drawState.resizeStart.x;
  //         const deltaY = e.clientY - drawState.resizeStart.y;

  //         console.log('Resizing', drawState.resizeStart.x, drawState.resizeStart.y, deltaX, deltaY);
  //         const newWidth = Math.max(((resizingBox.width / 100) * pdfDimensions.width + deltaX) / pdfDimensions.width * 100, BBOX_W_THRESH);
  //         const newHeight = Math.max(((resizingBox.height / 100) * pdfDimensions.height + deltaY) / pdfDimensions.height * 100, BBOX_H_THRESH);

  //         const updatedBox = {
  //             ...resizingBox,
  //             width: newWidth,
  //             height: newHeight,
  //         };

  //         onChange(value.map(box => box.id === resizingBox.id ? updatedBox : box));
  //     };

  //     window.addEventListener('mousemove', handleResize);
  //     window.addEventListener('mouseup', handleResizeEnd);
  // };

  // log every change of drawState
  useEffect(() => {
  }, [drawState]);

  // const handleResizeEnd = () => {
  //     setResizingBox(null);
  //     setDrawState({ ...drawState, isResizing: false });
  //     //window.removeEventListener('mousemove', handleResize);
  //     window.removeEventListener('mouseup', handleResizeEnd);
  // };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!drawState.isDrawing && !drawState.draggedBox && !resizingBox) return;

    const rect = drawingAreaRef.current?.getBoundingClientRect();
    if (!rect || !pdfDimensions) return;

    const currentPoint = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };

    if (drawState.draggedBox) {
      const newX = (currentPoint.x / pdfDimensions.width) * 100 - drawState.dragOffset.x;
      const newY = (currentPoint.y / pdfDimensions.height) * 100 - drawState.dragOffset.y;

      const updatedBox = {
        ...drawState.draggedBox,
        x: Math.max(0, Math.min(newX, 100 - drawState.draggedBox.width)),
        y: Math.max(0, Math.min(newY, 100 - drawState.draggedBox.height)),
      };
      if (drawState.draggedBox) {
        if (drawState.draggedBox) {
          onChange(value.map((box) => (box.id === drawState.draggedBox!.id ? updatedBox : box)));
        }
      }
    } else if (drawState.startPoint && drawState.previewBox) {
      setDrawState({
        ...drawState,
        previewBox: {
          ...drawState.previewBox,
          x: Math.min(drawState.startPoint.x, currentPoint.x),
          y: Math.min(drawState.startPoint.y, currentPoint.y),
          width: Math.abs(currentPoint.x - drawState.startPoint.x),
          height: Math.abs(currentPoint.y - drawState.startPoint.y),
        },
      });
    } else if (resizingBox) {
      const deltaX = e.clientX - drawState.resizeStart.x;
      const deltaY = e.clientY - drawState.resizeStart.y;

      const newWidth = Math.max(
        (((resizingBox.width / 100) * pdfDimensions.width + deltaX) / pdfDimensions.width) * 100,
        BBOX_W_THRESH,
      );
      const newHeight = Math.max(
        (((resizingBox.height / 100) * pdfDimensions.height + deltaY) / pdfDimensions.height) * 100,
        BBOX_H_THRESH,
      );

      const updatedBox = {
        ...resizingBox,
        width: newWidth,
        height: newHeight,
      };

      onChange(value.map((box) => (box.id === resizingBox.id ? updatedBox : box)));
    }
  };

  const handleMouseUp = () => {
    if (drawState.isDrawing && drawState.startPoint && drawState.previewBox && pdfDimensions) {
      const newBox: BoundingBox = {
        ...drawState.previewBox,
        id: Math.random().toString(36).substr(2, 9),
        x: (drawState.previewBox.x / pdfDimensions.width) * 100,
        y: (drawState.previewBox.y / pdfDimensions.height) * 100,
        width: (drawState.previewBox.width / pdfDimensions.width) * 100,
        height: (drawState.previewBox.height / pdfDimensions.height) * 100,
      };

      if (newBox.width >= BBOX_W_THRESH && newBox.height >= BBOX_H_THRESH) {
        onChange([...value, newBox]);
      }
    }
    setResizingBox(null);
    setDrawState({
      isDrawing: false,
      isDragging: false,
      isResizing: false,
      startPoint: null,
      previewBox: null,
      draggedBox: null,
      dragOffset: { x: 0, y: 0 },
      resizeStart: { x: 0, y: 0 },
    });
  };

  const handleBoxMouseDown = (e: React.MouseEvent, box: BoundingBox) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.button === 0) {
      // Left-click for dragging
      const rect = drawingAreaRef.current?.getBoundingClientRect();
      if (!rect || !pdfDimensions) return;

      const offsetX = ((e.clientX - rect.left) / pdfDimensions.width) * 100 - box.x;
      const offsetY = ((e.clientY - rect.top) / pdfDimensions.height) * 100 - box.y;

      setDrawState({
        ...drawState,
        draggedBox: box,
        dragOffset: { x: offsetX, y: offsetY },
      });
    } else {
      onChange(value.filter((b) => b.id !== box.id));
    }
  };

  const handleFileNameChange = (fileName: string) => {
    setOriginalFileName(fileName);
  };

  const handleResizeStartMouseDown = (e: React.MouseEvent, box: BoundingBox) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.button === 0) {
      // Left-click for resizing
      setResizingBox(box);
      setDrawState({
        ...drawState,
        isResizing: true,
        resizeStart: { x: e.clientX, y: e.clientY },
      });
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY > 0 ? -0.1 : 0.1;
        setScale((prevScale) => Math.max(0.1, Math.min(prevScale + delta, 5)));
      }
    };

    container.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LabelSelector activeLabel={activeLabel} onLabelSelect={setActiveLabel} labels={labels} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div style={{ display: "flex", gap: "16px" }}>
          <FormControlLabel
            control={<Switch checked={debugMode} onChange={(e) => setDebugMode(e.target.checked)} />}
            label="Debug Mode"
          />
          <FormControlLabel
            control={<Switch checked={activateDraw} onChange={(e) => setActivateDraw(e.target.checked)} />}
            label="Activate Draw"
          />
        </div>
        <LabellerPageNavigator
          value={currentPage}
          onChange={setCurrentPage}
          maxPage={maxPage}
          onChangeFile={() => setFile(null)}
          scale={scale}
          onChangeScale={setScale}
        />
      </div>
      <Box
        id="pdf-container"
        ref={containerRef}
        sx={{
          width: "100%",
          height: `${containerHeight}px`,
          overflow: "auto",
          mt: 2,
          border: "1px solid #ccc",
          position: "relative",
          display: "flex",
          justifyContent: "space-evenly", //center the pdf during zoom with space
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: pdfDimensions ? pdfDimensions.width : "100%",
            height: pdfDimensions ? pdfDimensions.height : "300px",
            border: debugMode ? "1px solid green" : "none",
            backgroundColor: debugMode ? "#00ff0010" : "transparent",
          }}
        >
          <PdfDocRenderer
            onFileSelect={setFile}
            file={file}
            onPageChange={setCurrentPage}
            debugMode={debugMode}
            onMaxPageChange={setMaxPage}
            page={currentPage}
            onDimensionsChange={handleDimensionsChange}
            scale={scale}
            onFileNameChange={handleFileNameChange}
          />
          {activateDraw && (
            <Box
              ref={drawingAreaRef}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                pointerEvents: "auto",
                backgroundColor: debugMode ? "#ff000010" : "transparent",
                border: debugMode ? "1px solid red" : "none",
                zIndex: 1000,
                cursor: activeLabel ? "crosshair" : "default",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onContextMenu={(e) => e.preventDefault()}
            >
              {value
                .filter((box) => box.page === currentPage)
                .map((box) => (
                  <Box
                    key={box.id}
                    sx={{
                      position: "absolute",
                      left: `${box.x}%`,
                      top: `${box.y}%`,
                      width: `${box.width}%`,
                      height: `${box.height}%`,
                      border: `2px solid ${box.label.color}`,
                      backgroundColor: `${box.label.color}33`,
                      cursor: "move",
                    }}
                    onMouseDown={(e) => handleBoxMouseDown(e, box)}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: -8,
                        right: -8,
                        width: "8px",
                        height: "8px",
                        backgroundColor: box.label.color,
                        cursor: "se-resize",
                        zIndex: 1000,
                      }}
                      onMouseDown={(e) => {
                        handleResizeStartMouseDown(e, box);
                      }}
                      onMouseUp={handleMouseUp}
                    />
                  </Box>
                ))}
              {drawState.previewBox && pdfDimensions && (
                <Box
                  sx={{
                    position: "absolute",
                    left: `${(drawState.previewBox.x / pdfDimensions.width) * 100}%`,
                    top: `${(drawState.previewBox.y / pdfDimensions.height) * 100}%`,
                    width: `${(drawState.previewBox.width / pdfDimensions.width) * 100}%`,
                    height: `${(drawState.previewBox.height / pdfDimensions.height) * 100}%`,
                    border: `2px dashed ${drawState.previewBox.label.color}`,
                    backgroundColor: `${drawState.previewBox.label.color}33`,
                    pointerEvents: "none",
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <PageTagSelector
        tagsOnPage={tags.filter((t: PageTag) => t.page === currentPage).map((t: PageTag) => t.tagName)}
        onChangeTagsOnPage={(newTags) => {
          onTagsChange([
            ...tags.filter((t: PageTag) => t.page !== currentPage),
            ...newTags.map((t) => ({ page: currentPage, tagName: t })),
          ]);
        }}
      />
    </Box>
  );
};

export default LabellerCanvas;
