import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Chip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

interface IPageTagSelectorProps {
  tagsOnPage: string[];
  onChangeTagsOnPage: (tags: string[]) => void;
}

const PageTagSelector: React.FC<IPageTagSelectorProps> = ({ tagsOnPage, onChangeTagsOnPage }) => {
  const [newTag, setNewTag] = useState("");
  const [tags, setTags] = useState<string[]>(JSON.parse(localStorage.getItem("availableTags") || "[]"));

  // Persist tags to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("availableTags", JSON.stringify(tags));
  }, [tags]);

  // Add new tag to available tags
  const handleAddTag = () => {
    if (newTag && !tags.includes(newTag)) {
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
      setNewTag(""); // Clear the input
    }
  };

  // Toggle tag selection and update parent state
  const handleToggleTag = (tag: string) => {
    const updatedSelectedTags = tagsOnPage.includes(tag) ? tagsOnPage.filter((t) => t !== tag) : [...tagsOnPage, tag];
    onChangeTagsOnPage(updatedSelectedTags); // Propagate the change to parent
  };

  // Remove tag from available tags list
  const handleDeleteTag = (tag: string) => {
    // show a confirmation dialog
    if (!window.confirm("Are you sure you want to delete this tag?")) {
      return;
    }

    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
    // Also remove from selected tags if it's currently selected
    if (tagsOnPage.includes(tag)) {
      const updatedSelectedTags = tagsOnPage.filter((t) => t !== tag);
      onChangeTagsOnPage(updatedSelectedTags); // Propagate the change to parent
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 2 }}>
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            color={tagsOnPage.includes(tag) ? "primary" : "default"}
            onClick={() => handleToggleTag(tag)}
            onDelete={() => handleDeleteTag(tag)}
            deleteIcon={<DeleteIcon />}
            sx={{ cursor: "pointer" }}
            // make it big
            size="medium"
          />
        ))}
      </Box>

      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
        <TextField
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          label="New Tag"
          variant="outlined"
          size="small"
        />
        <Button variant="contained" onClick={handleAddTag}>
          Add Tag
        </Button>
      </Box>
    </Box>
  );
};

export default PageTagSelector;
